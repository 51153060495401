.social-networks {
    overflow: hidden;

    li {
        height: 4.2rem;
    }

    li:first-child,
    > span {
        border-left: 1px solid rgba(109, 110, 112, .5);
    }

    ul,
    > span {
        float: left;
    }

    > span {
        height: 4.2rem;
        line-height: 4.2rem;
        margin: 0;
        padding: 0 1.2rem;
    }
}