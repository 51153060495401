// @name Wrapper with wide 940px
.wrapper-940 {
    width: 96rem;
    margin: 0 auto;
    padding: 0 1em;
    @extend .clear-fix;
}

// @name Wrapper with wide 1220px
.wrapper-1220 {
    max-width: 128rem;
    margin: 0 auto;
    padding: 0 3em;
    @extend .clear-fix;
}

// @name Wrapper with wide 1160px
.wrapper-1160 {
    max-width: 122rem;
    margin: 0 auto;
    padding: 0 3em;
    @extend .clear-fix;
}

// @name Wrapper with wide 1300px
.wrapper-1360 {
    max-width: 136rem;
    margin: 0 auto;
    padding: 0 3em;
    @extend .clear-fix;
}

// @name Background-in-wrapper
// @description Displayed in grey background on the sides.
.background-in-wrapper {
    position: relative;
    background: $color-3--1;
    &:before, &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 999em;
        height: 100%;
        background: $color-3--1;
    }
    &:before {
        left: -9990px;
    }
    &:after {
        right: -9990px;
    }
    // @description Displayed in dark background on the sides.
    &.background-in-wrapper--dark {
        background: $color-3--3;
        &:before, &:after {
            background: $color-3--3;
        }
    }
}

// @name Column bloc
// @description Generic bloc in .section-main__aside
$column-bloc__width: 35;
$column-bloc__padding-left: 8;
.column-bloc {
    width: 35rem;
    padding-left: 8rem;
    margin-bottom: 11rem;
}
.column-bloc__wrapper {
    padding: 0;
}