.location,
.content .location {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    &-list {
        order: 1;
        float: none;
        width: 100%;
        padding: 0 0 20px;
        li {
            width: 50%;
            display: inline-block;
            vertical-align: top;
        }
    }
    &-map {
        order: 2;
        width: 300px;
        margin: 0 auto 20px;
        padding: 0;
    }
    &-info {
        width: 300px;
        margin: 0 auto;
        float: none;
        order: 3;
        min-height: 0;
        &-title {
            font-size: 2rem;
        }
    }
}