.content-gallery {
    margin: 0 0 2.3rem;
    .listItems {
        margin: 0 1rem;
    }
    .item {
        vertical-align: top;
        &-inner {
            height: auto;
            padding: 0;
            background: transparent;
        }
        &-img {
            position: static;
        }
        &-subtitle {
            margin: 1.3rem 0 1.2rem;
        }
        &-person {
            margin: 1.5em 0 3.7rem 0;
        }
        &-content {
            width: 100%;
            position: relative;
            background: #fcd808;
            z-index: 2;
            &:before {
                display: none;
            }
            &-inner {
                position: static;
                transform: none;
                padding: 5.5rem 0 2rem;
            }
        }
        &-decore {
            left: 38%;
            &-1 {
                display: none;
            }
            &-2 {
                bottom: auto;
                right: -12rem;
                left: auto;
                top: -2rem;
            }
        }
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        top: 0;
        transform: none;
        button {
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
        }
    }
    .prevCaroussel3 {
        left: -1rem;
    }
    .nextCaroussel3 {
        right: -1rem;
        button {
            right: 0;
            left: auto;
        }
    }
}