.project-grand {
    display: flex;
    margin: 0 0 35px;
    &-img {
        flex: 3;
        position: relative;
        min-height: 436px;
        overflow: hidden;
        img {
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-width: none;
        }
    }
    &-description {
        flex: 0.76;
        margin: 0 0 0 20px;
        position: relative;
        padding: 58px 34px 40px;
        z-index: 1;
        &:after {
            position: absolute;
            bottom: -47px;
            left: -288px;
            width: 451px;
            height: 451px;
            z-index: -2;
            pointer-events: none;
            background: url(../Images/pictos/decore-circle-5.png) no-repeat;
            content: '';
        }
        &:before {
            background: #fff;
            border: 1px solid #111212;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            content: '';
        }
        h3 {
            font-size: 2rem;
            line-height: 1.1;
            text-transform: uppercase;
            font-family: $typo-1;
            font-weight: 700;
            margin: 0 0 23px;
            a {
                background: none;
                color: #000;
                &:hover,
                &:focus {
                    color: #000;
                    text-decoration: underline;
                }
            }
        }
        p {
            font-size: 1.6rem;
            line-height: 1.2;
            margin: 0 0 17px;
        }
        a {
            padding: 0;
            &:hover,
            &:focus {
                p {
                    text-decoration: underline;
                }
            }
        }
        .title-4 {
            margin-top: -76px;
            margin-bottom: 42px;
        }
        .btn-plus {
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
}