// @name Videos
.content {
    .videos {
        display: block;
        margin: 5.8rem 6% 5.7rem;
        width: 100%;
    }
    .video__title {
        $video__title__font-size: 1.6;
        font-family: $typo-2;
        text-align: left;
        font-weight: $light;
        font-size: 1.5rem;
        color: #727272;
        margin-bottom: 1.3rem;
    }
    .videos__item {
        position: relative;
        width: 100%;
        margin: 0 auto 25px;
        padding: 0 0 56.25%;
        video {
            display: block;
            width: 100%;
        }
        iframe,
        .iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            margin: 0;
        }
    }
    .videos__ratio {
        display: block;
        width: 100%;
        height: auto;
    }
    .ce-textpic {
        .videos,
        .videos__item {
            margin: 0;
        }
    }
}

// @name Videos for popin
// @description if only one, no margin
.popin {
    #content {
        .content,
        .setPos {
            &:only-of-type {
                .videos {
                    margin: 0;
                    .videos__item {
                        &:only-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}