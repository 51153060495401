.menu-cross {
    width: auto;
    float: none;
    padding: 0;
    width: 60%;
    margin: 0 0 0 50%;
    position: relative;
    left: -48px;

    ul {
        margin-bottom: 13px;
    }

    li {
        margin-bottom: 1px;
    }

    &-important {
        li {
            margin-bottom: 4px;
        }
    }
}