.partners-carousel,
.content .partners-carousel {
    margin: 0;
    padding: 0 31px;
    &-img {
        margin-bottom: 26px;
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        top: 25px;
        &:after {
            font-size: 14px;
        }
    }
    .prevCaroussel3 {
        left: -7px;
    }
    .nextCaroussel3 {
        right: -7px;
    }
}