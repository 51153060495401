// Font
// import google font
@if variable-exists(import-google-font-2) {
    @import url($import-google-font-2);
}

@if variable-exists(import-google-font-3) {
    @import url($import-google-font-3);
}

@if variable-exists(import-google-font-4) {
    @import url($import-google-font-4);
}

// Montserrat
// Light
@include fontFace(montserrat, 'montserrat-light-webfont', $light, normal);
// Normal
@include fontFace(montserrat, 'montserrat-regular-webfont', $normal, normal);
// Bold
@include fontFace(montserrat, 'montserrat-bold-webfont', $bold, normal);
// Black
@include fontFace(montserrat, 'montserrat-black-webfont', $black, normal);

// Icon fonts
// create font-face for icon fonts
@include fontFace(icons-default, 'default-icons-webfont', normal, normal);
@include fontFace(icons-project, 'icons', normal, normal);

@include fontFace(cheddar-jack, 'cheddar_jack', $normal, normal);

// @name Body
body {
    font-family: $typo-1;
    font-weight: $light;
    overflow-x: hidden;
}

// hide SVG symbol
.svg-symbol {
    display: none;
}