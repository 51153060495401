.hero {
    position: relative;
    overflow: hidden;
    a {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 5;
        &:hover,
        &:focus {
            .linkBlock {
                text-decoration: underline;
            }
        }
    }

    &__skew {
        position: absolute;
        z-index: 100;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 8.8rem;
    }

    &__img {
        height: 46.4rem;
        background-position: 50% 50%;
        background-size: cover;
    }

    &__lang {
        @extend .wrapper-1220;

        position: absolute;
        z-index: 101;
        left: 0;
        right: 0;
        bottom: 4.8rem;
        width: 100%;

        .lang-switch {
            margin-left: -0.5rem;
        }
    }

    &__lang-decore {
        position: absolute;
        left: 2.5rem;
        bottom: -2.5rem;
        width: 31rem;
        height: 31rem;
        background: url(#{$image-pictos-url}decore-circle.png) 50% 50% no-repeat;
        background-size: 100% auto;
    }

    &__gallery {
        position: relative;
        //height: 74.5rem;/
        height: 60rem;
        background: $color-3--3;

        .pagerCarrousel1 {
            display: none;
        }

        .listItems,
        .item {
            height: 100%;
            width: 100%;
        }

        .item {
            background-size: cover;
            background-position: 50% 50%;
            &-video {
                background: #000;
                iframe {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    height: calc(100% - 90px);
                }
            }
        }

        .prevCarrousel1,
        .nextCarrousel1 {
            position: absolute;
            z-index: 101;
            top: 50%;
            transform: translateY(-50%);
            width: 4.2rem;
            height: 4.2rem;
            margin-top: -1.7rem;

            button {
                @include default-icons-absolute-before('', 15px, $color-white, 50%, auto, auto, 50%);

                width: 100%;
                height: 100%;
                border-radius: 50px;
                background: $color-1--1;

                &:before {
                    font-weight: $normal !important;
                    transform: translate(-50%, -50%);
                }

                img {
                    display: none;
                }
            }
        }

        .prevCarrousel1 {
            left: .9rem;

            button {
                &:before {
                    content: '\e016';
                }
            }
        }

        .nextCarrousel1 {
            right: .9rem;

            button {
                &:before {
                    content: '\e017';
                }
            }
        }

        .buttonStop {
            @include default-icons-before('\e075', 0 -0.4rem 0 0, 12px, $color-white);
            @include default-icons-after('\e076', 0, 12px, $color-white);

            position: absolute;
            z-index: 101;
            right: 3.9rem;
            bottom: 3.9rem;

            img,
            .txt {
                display: none;
            }
        }

        .wrapper {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: 11.4rem;
            max-width: 82.6rem;
            width: 100%;
            margin: 0 auto;
            padding: 0 3rem;

            &__content {
                position: relative;
                border-radius: 50%;
                width: 41.6em;
                height: 41.6em;
                padding: 9.8rem 5.6rem;
                background: rgba(252, 216, 8, .8);
                font-family: $typo-1;
                text-transform: uppercase;

                &:before {
                    content: '';
                    position: absolute;
                    right: -5.8rem;
                    top: 0.2rem;
                    width: 17.1rem;
                    height: 17.1rem;
                    background: url(#{$image-pictos-url}decore-circle-4.png) 50% 50% no-repeat;
                }

                .category {
                    position: relative;
                    padding: 0 0 1.8rem;
                    margin: 0 0 0.7rem;
                    font-weight: $light;
                    font-size: 1.7rem;
                    line-height: 1;
                    font-family: $typo-1;
                    background: none;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 12.7rem;
                        height: .6rem;
                        background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
                    }

                    span {
                        color: inherit;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .title {
                    position: relative;
                    font-weight: $bold;
                    font-size: 3.2rem;
                    line-height: 1;

                    span {
                        color: $color-1--2;
                    }
                    sup {
                        font-size: 73%;
                    }
                }

                .moreInfos {
                    @include default-icons-absolute-before('\e08a', 14px, $color-white, 50%, auto, auto, 50%);

                    position: absolute;
                    bottom: 1.4rem;
                    right: 5.6rem;
                    border-radius: 50%;
                    background: $color-1--1;
                    width: 5.1rem;
                    height: 5.1rem;
                    overflow: hidden;

                    &:before {
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
    }
}