// @name bloc publications

// @name Bloc publications in column and in go-so-far
.bloc-publications__listitems {
    padding-top: 7px;
    position: relative;
}

.bloc-publications__picture {
    display: block;
    float: left;
    margin: 0 15px 0 0;
    border: 1px solid #d8ba07;
}

.bloc-publications__wrapper {
    overflow: hidden;
    margin-top: -2px;
}

.bloc-publications__title {
    color: #111212;
    font-size: 1.8rem;
    line-height: 1.2;
    font-weight: 400;
    font-family: $typo-2;
    margin: 0 0 20px;
    text-transform: uppercase;
    a {
        color: #111212;
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
        }
    }
}

.column-bloc {
    .bloc-publications__title {
        $bloc-publications__title__font-size: 1.6;
        font-size: #{$bloc-publications__title__font-size}em;
        margin: 0 0 em(.8, $bloc-publications__title__font-size);
    }
}