.top-bar {
    @extend .clear-fix;

    position: absolute;
    z-index: 101;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, .7);
    padding: .9rem 3.4rem;
    width: 100%;

    .social-networks {
        margin-right: -3.4rem;
    }

    &__right {
        float: right;
    }

    &__left {
        float: left;
    }

    &__info {
        color: $color-white;
        text-transform: uppercase;
        font: 13px/1 oswald, arial, helvetica, sans-serif;
        padding: 0.3rem 0;

        a {
            color: inherit;

            &:hover {
                text-decoration: none;
            }
            &:focus {
                outline-color: #fff;
            }
        }

        img,
        p {
            display: inline-block;
            vertical-align: middle;
        }

        img {
            margin-right: 1.7rem;
        }

        p {
            margin: 0;
        }
    }
}