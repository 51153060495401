// @name Main section heading
// @description Default css
$heading__h1__font-size: 4.5;
.heading {
    padding: 5.2rem 0 2.5rem;
    .heading__wrapper {
        @extend .wrapper-1220;
        > *:last-child {
            margin-bottom: 0;
        }
    }
    .heading-buttons {
        padding-top: 5px;
        float: right;
    }
    .heading__figure {
        margin-right: 2em;
        display: table;
    }
    // right image
    .heading__wrap {
        + .heading__wrapper-figure {
            .heading__figure {
                margin-right: 0;
                margin-left: 2em;
            }
        }
    }
    .heading__picture {
        display: block;
        margin-bottom: .5em;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .heading__subtitle {
        font-size: 2rem;
        line-height: 1.2;
        display: block;
    }
    .heading__caption {
        display: table-caption;
        caption-side: bottom;
    }
    h1 {
        font-family: $typo-3;
        font-weight: $bold;
        font-size: #{$heading__h1__font-size}em;
        text-transform: uppercase;
        line-height: 1.3;
        color: $color-1--1;
    }
    h1 + .teaser-2 {
        margin-top: 1.1rem;
    }
    .subtitle {
        font-size: 1.7rem;
        padding: 0 15px;
    }
    .subtitle + h1 {
        margin-top: 0.4rem;
    }
    .date {
        font-size: 1.4em;
    }
    .status {
        $status__font-size: 1.5;
        background: $color-white;
        font-size: #{$status__font-size}em;
        margin-top: em(0.5, $status__font-size);
        display: inline-block;
        &.status--new {
            background: $color-1--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
        &.status--in-progress {
            background: $color-2--2;
            color: $color-white;
            &:before {
                content: "\e093";
                color: $color-white;
            }
        }
    }
}

// @name Heading single
// @dependency .heading
.heading--single {
    .heading__wrapper {
        display: flex;
        flex-wrap: nowrap;
        > .heading__wrapper-figure {
            flex-basis: 30%;
        }
        > .heading__wrap {
            flex-basis: 70%;
        }
    }
    .heading__media {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid darken($color-3--1, 20%);
        .date, .media {
            font-size: 1.6em;
        }
        .media {
            font-weight: $bold;
            border: none;
            & + .date {
                &:before {
                    content: " - ";
                    display: inline;
                    font-size: 1.2em;
                    color: $color-3--3;
                }
            }
        }
    }
}

// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper {
        display: block;
        flex-wrap: inherit;
    }
    .heading__figure {
        margin-right: 0;
    }
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: table-cell;
        vertical-align: top;
    }
    .heading__picture {
        img {
            width: 280px;
            max-width: inherit!important;
            height: auto;
        }
    }
    .heading__wrapper-date {
        width: 16em;
        border-right: 1px solid $color-3--3;
        .date-1 {
            margin: 0 2rem 0.5rem;
            width: 14.5rem;
            height: 14.5rem;
            display: block;
            padding-top: 2.5rem;
            time {
                display: inline-block;
            }
        }
        .hour {
            margin-top: 1rem;
        }
    }
    .heading__wrapper-calendar-button {
        margin: 1em 1em 0;
        line-height: normal;
    }
    .heading__wrap {
        padding-left: 2em;
    }
    .button-1 {
        margin-top: 2rem;
        float: right;
    }
}

// @name Heading single publications
// @dependency .heading
.heading--single-publications, .heading--single-public-market {
    .heading__picture {
        img {
            width: 220px;
            max-width: inherit;
            height: auto;
        }
    }
}

// @name Heading single directory
// @dependency .heading
.heading--single-directory {
    .list-infos {
        margin-top: 2em;
    }
    .list-infos__wrapper {
        .list-infos__listitems:first-child {
            flex: 1 1 0;
        }
    }
    .list-infos__item {
        padding-left: em(2, 1.6);
        font-size: 1.6em;
    }
}
