.event-popup {
    overflow: hidden;
    display: none;
    &-banner {
        margin-bottom: -5.8%;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &-bottom-bar {
        background: #fff;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        padding: 0 41px 28px;
        z-index: 1;
        &-l {
            &:after {
                background: #fff;
                height: 50px;
                position: absolute;
                top: -25px;
                left: -50px;
                right: -50px;
                transform: rotate(-2deg);
                z-index: -1;
                content: '';
            }
        }
        a {
            display: inline-block;
            padding: 0 15px;
            vertical-align: bottom;
        }
        &-r {
            margin-top: -7px;
        }
    }
    &-close {
        position: absolute;
        border-radius: 50%;
        right: 1.2rem;
        top: 1rem;
        width: 3.8rem;
        height: 3.8rem;
        border: 3px solid $color-3--2;
        background: $color-white;
        cursor: pointer;
        padding: 0;
        overflow: hidden;
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 12px;
            background: #797979;
            margin: auto;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}