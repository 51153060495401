// @name Go so far
// @description Sections content below the content

.go-so-far {
    background: $color-2--1;
    padding: 4.8em 0 10em;
}

.go-so-far__title {
    margin-top: 0;
}

.go-so-far__wrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -20px;
    > * {
        flex: 1.8;
        padding: 0 20px;
        &:last-child {
            flex: 2;
        }
    }
}

.go-so-far__item {

}
