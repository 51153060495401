.kiosk {
    &__col {
        width: 50%;
        padding: 0 !important;
        &_lg {
            width: 100%;
            float: none;
            margin: 0 0 12px;
        }
    }
}