.cartographie {
    position: static;
    min-height: 0;
    height: auto;
    &-sidebar {
        width: 100%;
        float: none;
        height: auto;
        .pager {
            ul {
                margin: 0;
            }
            .pager-single__back-to-list {
                a {
                    text-align: center;
                }
            }
        }
    }
    &-map {
        width: 100%;
        height: auto;
        float: none;
        position: static;
        &-inner {
            position: static;
            height: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        &-wrap {
            height: 300px;
            order: 2;
        }
    }
    &-results {
        display: none;
        margin: 0;
        width: 100%;
        position: static;
        float: none;
        height: auto;
        order: 1;
        &-opened {
            .cartographie-results {
                display: block;
            }
        }
    }
    &-info {
        order: 3;
        display: none;
        margin: 0;
        height: auto;
        width: 100%;
        position: static;
        float: none;
        &-opened {
            .cartographie-info {
                display: block;
            }
        }
    }
}