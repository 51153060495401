// Galerie classique (avec vignettes) + Galerie album
.content {
    .classical-gallery {
        margin-right: -10px;
        margin-left: -10px;
        margin-bottom: 82px;
        width: auto;
        position: relative;
        .carousel-galerie__thumb {
            display: none;
        }
    }
    .slider-galerie {
        margin: 0;
        .infos-img {
            display: block;
            width: 100%;
            height: 60px;
            margin: 0;
            padding: 5px;
        }
    }
    .carousel-galerie {
        position: static;
        display: block;
    }
    .carousel-galerie__pager {
        position: static;
        width: auto;
        height: auto;
    }
    .carousel-galerie__prev,
    .carousel-galerie__next {
        width: 22px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
        margin: 0;
        button {
            border: none;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5) !important;
            border-radius: 0;
            &:focus {
                outline-color: #fff;
            }
            &:before {
                color: #fff;
                font-weight: 400;
                content: '\e01a';
                font-size: 1.6rem;
            }
        }
    }
    .carousel-galerie__next {
        left: auto;
        right: 0;
        button {
            &:before {
                content: '\e01b';
            }
        }
    }
}