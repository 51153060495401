// @name Title 1
// @description Styling for title 1
$title--1__font-size: 4.4;
.title-1 {
    font-family: $typo-3;
    font-size: #{$title--1__font-size}em;
    line-height: 1em;
    font-weight: $normal;
    text-transform: uppercase;
    margin: em(3, $title--1__font-size) 0 em(2, $title--1__font-size);
}

// @name Title 2
// @description Styling for title 2
$title--2__font-size: 1.8;
.title-2 {
    font-family: $typo-1;
    font-size: #{$title--2__font-size}em;
    font-weight: $bold;
    color: $color-white;
    padding: 6px 18px;
    background-color: $color-black;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 0 12px;
}

// @name Title 3
// @description Styling for title 3
$title--3__font-size: 1.8;
.title-3 {
    font-family: $typo-3;
    font-size: #{$title--3__font-size}em;
    line-height: 1em;
    font-weight: $bold;
    padding: 8px 17px;
    text-align: center;
    text-transform: uppercase;
    color: $color-1--1;
    display: inline-block;
    vertical-align: top;
    background: $color-2--1;
    margin: em(2.5, $title--3__font-size) 0 em(2, $title--3__font-size);
    //padding-bottom: em(1.6, $title--3__font-size);
    //border-bottom: 1px solid $color-3--2;
}

.title-4,
.content .title-4 {
    text-align: center;
    margin-bottom: 20px;
    h2 {
        background: #fcd808;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        color: #111212;
        font-size: 1.8rem;
        line-height: 1.2;
        font-family: $typo-1;
        font-weight: 700;
        padding: 6px 20px;
        margin: 0;
    }
}

.decor-title {
    justify-content: center;
    display: flex;
    margin: 0 0 30px;
    &-c {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        &:after,
        &:before {
            width: 131px;
            height: 6px;
            background: url(../Images/bg/sep.png) repeat-x;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            content: '';
        }
        &:before {
            left: -160px;
        }
        &:after {
            right: -160px;
        }
    }
    &-ico {
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background: #fff;
        position: relative;
        margin: 0 13px 0 0;
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }
    h2 {
        color: #111212;
        font-size: 3.4rem;
        line-height: 1.2;
        margin: 0;
        text-transform: uppercase;
        font-weight: 700;
    }
}