.publication {
    &__img {

    }
    &__content {
        padding: 14px 0 0;
    }
    &__head {
        width: 100%;
        float: none;
        margin: 0 0 1.1rem;
    }
    &__controls {
        width:: 100%;
        float: none;
        padding: 9px 0 43px;
    }
    &_sm {
        margin-bottom: 25px !important;
        .publication__img {
            float: left !important;
            width: 91px !important;
            margin: 0 16px 0 0 !important;
            text-align: left;
        }
        .publication__content {
            overflow: hidden;
        }
        .publication__controls {
            padding-bottom: 0;
        }
    }
}

.publication__img,
.content .publication__img {
    width: 254px;
    margin: 0 auto;
    float: none;
}