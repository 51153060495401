// @name Signature stratis
.stratis {
    background: #111212;
    width: 100%;
    font-size: 1.2rem;
    color: #fff;
    text-align: right;
    font-family: $typo-2;
    text-transform: uppercase;
    span {
        display: none;
    }
    a {
        display: inline-block;
        padding: 4px 10px;
        margin: 0 0 0 10px;
        color: #ffffff;
        background: #c70000;
        text-decoration: none;
        font-weight: 700;
        &:hover, &:focus {
            color: #c70000;
            background: #ffffff;
        }
    }
}