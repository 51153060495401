// @name Main section heading
// @description Default css
.heading {
    padding: 3.8rem 0 2rem;

    &__category {
        margin-bottom: 10px;
    }
    .heading__wrapper {
        padding: 0 10px 0 10px;
    }
    .heading__figure {
        width: 100%;
        float: none;
        margin-right: 0;
        margin-bottom: 2em;
    }
    // right image
    .heading__wrap + .heading__wrapper-figure {
        margin-top: 2em;
        .heading__figure {
            margin-left: 0;
        }
    }
    h1 {
        font-size: 2.8em;
        line-height: 1;
        + .teaser-2 {
            margin-top: 2.1rem;
        }
    }
    .subtitle {
        font-size: 1.4rem;
        padding: 1px 13px;
        + h1 {
            margin-top: 0.7rem;
        }
    }
    .heading-buttons {
        padding-top: 0;
        margin-top: -5px;
    }
}

// @name Heading single
// @dependency .heading
.heading--single {
    .heading__wrapper {
        flex-direction: column;
        > .heading__wrapper-figure, > .heading__wrap {
            flex-basis: 100%;
        }
    }
}

// @name Heading single event
// @dependency .heading
.heading--single-event {
    .heading__wrapper-figure, .heading__wrapper-date, .heading__wrap {
        display: block;
        vertical-align: inherit;
    }
    .heading__picture {
        img {
            width: auto;
            max-width: 100%;
        }
    }
    .heading__wrapper-date {
        width: 100%;
        border-right: 0;
        display: table;
        border-bottom: 1px solid $color-3--3;
        margin-bottom: 2em;
        .date-1, .hour-place {
            display: table-cell;
            vertical-align: middle;
            padding-top: 0;
        }
        .date-1 {
            time:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
    .heading__wrap {
        padding-left: 0;
    }
}

.heading-buttons a {
    width: 40px;
    height: 40px;
    margin: 0 0 0 3px;
    padding: 0 !important;
}
