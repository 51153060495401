.project-directives {
    @extend .clear-fix;

    padding: 0 0 0 25.7rem;
    margin: 0 -0.2rem 6.2rem 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &:before {
        content: '';
        position: absolute;
        left: 35rem;
        right: 9.4rem;
        top: 50%;
        transform: translateY(-50%);
        height: 10.9rem;
        background: url(#{$image-bg-url}plus-pattern.png) 3px 0;
    }

    &__item,
    .content &__item {
        width: 18.9rem;
        color: $color-white;
        text-transform: uppercase;
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 1.2;
        font-family: $typo-2;
        float: left;
        margin: 0 .2rem;
        padding: 0;
        text-align: center;
        background: none;
        position: relative;

        &:hover {
            color: $color-1--1;

            mark {
                color: inherit;
            }
        }

        &:last-child {
            &:before {
                content: '';
                width: 17.9rem;
                height: 17.9rem;
                background: url(#{$image-pictos-url}decore-circle-4.png);
                background-size: 100% auto;
                position: absolute;
                right: -5.5rem;
                top: -5.5rem;
                pointer-events: none;
            }
        }

        mark {
            color: $color-2--1;
            background: none;
        }

        .name,
        .years {
            font-family: $typo-1;
            font-size: 2.8rem;
            line-height: 1;
        }

        .name {
            font-weight: $bold;
            position: relative;
            padding: 0 0 1.8rem;
            margin: 0 0 1rem;
            white-space: nowrap;

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
                height: .6rem;
                width: 13rem;
            }
        }

        .years {
            text-align: right;
            font-weight: $light;
        }
    }

    &__item_lead,
    .content &__item_lead {
        position: absolute;
        left: -1.8rem;
        top: 50%;
        transform: translateY(-50%);
        color: $color-1--1;
        width: 24.6rem;
        text-align: left;

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-size: 100% auto;
        }

        &:before {
            right: -0.3rem;
            top: -2.7rem;
            width: 22.7rem;
            height: 22.7rem;
            background-image: url(#{$image-pictos-url}decore-circle.png);
        }

        &:after {
            left: -2rem;
            bottom: -2.8rem;
            width: 17.5rem;
            height: 17.5rem;
            background-image: url(#{$image-pictos-url}decore-circle-4.png);
        }
    }

    &__item_lead &__item-inner {
        background: $color-2--1;
    }

    &__item_lead &__item-content {
        padding: 4.3rem 0 4.3rem 4.3rem;
        width: 102%;
    }

    &__item-inner {
        position: relative;
        border-radius: 50%;
        background: $color-1--1;
        padding-bottom: 100%;
        z-index: 2;
    }

    &__item:hover &__item-inner {
        background: $color-2--1;
    }

    &__item-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2rem;
        width: 100%;
    }
}