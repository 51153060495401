.logo {
    position: absolute;
    top: 0.4rem;
    font-size: 1.3rem;
    width: 16.7rem;
    z-index: 2;

    a {
        display: block;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}