.footer-social {
    float: left;
    width: 23%;
    text-align: center;
    margin: 3px 4% 0 0;
    .button-2 {
        font-size: 1.4rem;
        padding: 7px 26px 7px;
        font-family: $typo-1;
        font-weight: $bold;
        .ico {
            width: 40px;
            height: 40px;
            margin: 0 4px 0 0;
        }
    }
    &-list {
        margin: 25px -2px 0;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 20%;
            padding: 0 2px;
        }
        a {
            display: block;
            padding-top: 100%;
            border: 1px solid #fcd808;
            position: relative;
            border-radius: 50%;
            svg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                fill: #FCD805;
            }
            &:hover,
            &:focus {
                border-color: #555;
                background: #fff;
                svg {
                    fill: #555;
                }
            }
        }
    }
}