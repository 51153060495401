// Galerie classique
.content {
    .classical-gallery__title {
        $classical-gallery__title_font-size: 1.6;
        font-family: $typo-2;
        text-align: right;
        font-weight: $normal;
        font-size: #{$classical-gallery__title_font-size}em;
        color: $color-3--3;
        margin: em(3, $classical-gallery__title_font-size) 0 em(1, $classical-gallery__title_font-size) 0;
    }
    .classical-gallery {
        margin: 6rem 6.7% 5.6rem;
        &__nb-items {
            display: none;
        }

        a {
            background: none;
        }
    }
    .slider-galerie {
        width: 100%;
        height: 100%;
        position: relative;
        margin-bottom: 1.1rem;
        .slider-galerie__item {
            width: 100%;
            height: 100%;
            z-index: 1 !important;
            border: none;
        }
        .infos-img {
            position: absolute;
            z-index: 1;
            top: 100%;
            right: 0;
            width: calc(100% - 492px);
            display: table;
            padding: 10px;
            font-family: $typo-2;
            font-weight: $light;
            color: #727272;
            font-size: 1.3em;
            text-align: left;
            white-space: normal;
            margin: 13px 0 0;
            &__nbItems {
                display: table-cell;
                vertical-align: bottom;
                white-space: nowrap;
                width: 70px;
            }
            &__wrap {
                display: table-cell;
                vertical-align: top;
            }
            &__legend {
                display: block;
            }
        }
        a {
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            position: relative;
            white-space: nowrap;
            // padding: 68.08510% 0 0 0; // 100/(940/640)
            padding: 0 0 (476 / 701 * 100%);
            figure {
                display: inline;
            }
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            bottom: 0;
            left: 50%;
            right: 0;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .sliderVideo {
        margin-bottom: 0;
        .infos-img {
            position: inherit;
            bottom: inherit;
            top: inherit;
            left: inherit;
            right: inherit;
        }
    }
    .carousel-galerie {
        width: 500px;
        display: inline-block;
        text-align: right;
        position: relative;
        z-index: 1;
        .item {
            border: none;
        }
    }
    .carousel-galerie__thumb {
        width: 347px;
        height: 84px;
        z-index: 2;
        margin-left: 6.2rem;
        text-align: left;
        &.no-carousel {
            position: relative;
            .carousel-galerie__item {
                display: inline-block;
            }
        }
    }
    .carousel-galerie__item {
        z-index: 1 !important;
        text-align: center;
        &.cycle-slide-active button, &.cycle-pager-active button, button:hover, button:focus {
            &:before {
                background: rgba(0, 0, 0, 0);
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 50%;
                border: 10px solid $color-2--1;
                background: rgba(0, 0, 0, .5);
            }
        }
        button {
            position: relative;
            img {
                width: 84px;
                height: 84px;
                border-radius: 50%;
            }
            &:focus {
                outline-offset: -1px;
            }
        }

        &.cycle-slide-active {
            button {
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 50%;
                    border: 10px solid $color-2--1;
                    background: rgba(0, 0, 0, .5);
                }
            }
        }
    }
    .carousel-galerie__pager {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        height: 84px;
        width: 474px;
        z-index: 1;
    }
    .carousel-galerie__prev, .carousel-galerie__next {
        position: absolute;
        padding: 0;
        top: 50%;
        transform: translateY(-50%);
        button {
            overflow: hidden;
            width: 45px;
            height: 45px;
            margin: auto;
            border-radius: 50%;
            border: $color-3--2 solid 3px;
            background: $color-white;
            text-indent: -9999px;
            transition: all ease .2s;
            @include default-icons-absolute-before('\e026', 1.4rem, $color-3--3, 49%, inherit, inherit, 49%);
            &:before {
                text-indent: 0;
                transform: translateY(-50%) translateX(-50%);
            }
            &:hover, &:focus {
                background: $color-1--3;
            }
        }
        &:before {
            content: "";
            speak: none;
        }
    }
    .carousel-galerie__prev {
        left: 0;
    }
    .carousel-galerie__next {
        right: 0;
        button {
            &:before {
                content: "\e027";
            }
        }
    }
}
