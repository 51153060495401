.location,
.content .location {
    overflow: hidden;
    padding-top: 16px;
    &-list {
        width: 180px;
        float: left;
        font-size: 0;
        margin: 0;
        padding: 10px 0 0;
        a {
            background: none;
            padding: 0;
            &:hover,
            &.active {
                font-weight: bold;
                text-decoration: underline;
            }
        }
        li {
            text-transform: uppercase;
            font-weight: $light;
            font-size: 1.5rem;
            line-height: 1.1;
            font-family: $typo-2;
            padding-left: 1.5rem;
            margin: 0 0 .4rem;
            &:before {
                content: '\e011';
                font-size: 1.6rem;
                top: .3rem;
            }
            &:nth-child(odd) {
                padding-right: 1.5rem;
            }
        }
    }
    &-info {
        float: right;
        width: 270px;
        min-height: 100px;
        padding: 6px 0 0;
        position: relative;
        &-close {
            position: absolute;
            top: 4px;
            right: 1px;
            width: 30px;
            height: 30px;
            overflow: hidden;
            &:after,
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                -ms-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 15px;
                height: 2px;
                background: #111212;
                content: '';
            }
            &:before {
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
        &-title {
            color: #111212;
            font-size: 3.3rem;
            line-height: 1.2;
            text-transform: uppercase;
            font-family: $typo-2;
            padding: 0 50px 0 0;
            margin: 0 0 15px;
            font-weight: 400;
        }
        &-img {
            margin: 0 0 18px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        p {
            margin: 0 0 24px;
            font-size: 1.3rem;
            line-height: 1.2;
        }
        &-list {
            margin: 0 0 31px;
            li {
                margin: 0 0 9px;
                padding: 0;
                font-size: 1.3rem;
                line-height: 1.25;
                &:before {
                    display: none;
                }
                strong {
                    display: block;
                    font-family: $typo-2;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
    }
    &-map {
        overflow: hidden;
        padding: 0 2.2% 0 1%;
        &-inner {
            padding-top: 91%;
            position: relative;
            &:before,
            &:after {
                content: '';
                position: absolute;
                background-size: 100% auto;
                background-repeat: no-repeat;
            }
            &:before {
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
                width: 84.2%;
                height: 100%;
                background-image: url(#{$image-pictos-url}decore-circle-7.png);
            }
            &:after {
                background-image: url(../Images/map/map-shadow.png);
                left: -4px;
                right: -4px;
                top: -4px;
                bottom: -4px;
            }
            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: auto;
                z-index: 2;
            }
            path:first-child {
                pointer-events: none;
            }
            path:not(:first-child) {
                fill: transparent;
                cursor: pointer;
                &.active {
                    fill: $color-1--1;
                }
            }
        }
    }
}