.newsletter {
    font-weight: $light;
    font-size: 1.3rem;
    line-height: 1.2;
    color: #010101;

    form {
        margin: 0 0 1.2rem;
    }

    p {
        font-size: 1.3rem;
        margin: 0 0 2.6rem;
    }

    label {
        text-transform: uppercase;
        font-weight: $normal;
        font-size: 1.5rem;
        line-height: 1;
        font-family: $typo-2;
        margin: 0 0 0.9rem;
    }

    [type=email] {
        padding: 0;
        background: none;
        border: solid $color-3--3;
        border-width: 0 0 1px;
        height: 3.6rem;
        color: $color-black;
        margin: 0 0 1.5rem;
        font-size: 1.3rem;

        &::-webkit-input-placeholder {
            color: rgba(0, 0, 0, .4);
        }

        &::-moz-placeholder {
            opacity: 1;
            color: rgba(0, 0, 0, .4);
        }

        &:-ms-input-placeholder {
            color: rgba(0, 0, 0, .4);
        }
    }

    a {
        display: block;
        font-size: 1.6rem;
        height: 4.5rem;
        width: 100%;
        text-align: center;

        .check {
            color: $color-white;

            &:before {
                left: 0 !important;
            }
        }

        .play {
            color: $color-white;

            &:before {
                font-family: "icons-default";
                content: "\e027";
            }
        }
    }
    a:first-child {
        margin-bottom: 0.8rem;
    }

    .button-2 {
        font-size: 1.6rem;
    }

    &__title,
    .content &__title {
        text-transform: uppercase;
        color: #282d33;
        font-weight: $black;
        font-size: 2.2rem;
        line-height: 1;
        margin: 0 0 1.8rem;
        max-width: 80%;
        padding: 0;
    }

    &__inner {
        background: $color-3--1;
        padding: 3rem;
    }

    &__links,
    .content &__links {
        text-transform: uppercase;
        font-weight: $light;
        font-size: 1.3rem;
        line-height: 1;
        font-family: $typo-2;
        margin: 0;

        a {
            background: none;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 3.1rem .5rem 0;
            padding: 0;

            &:before {
                content: '\e017';
                color: inherit;
                font-size: 0.8rem;
                position: relative;
                top: -1px;
            }
        }
    }
}
