.lang-switch {
    position: relative;
    z-index: 1;
    display: block;
    width: 5.4rem;
    cursor: pointer;
    font: 700 1.2rem/1 lato, arial, helvetica, sans-serif;
    color: $color-1--1;
    text-transform: uppercase;

    a {
        color: inherit;
    }

    &__current {
        position: relative;
        border-radius: 50%;
        height: 0;
        padding-bottom: 100%;
        background: url(#{$image-pictos-url}btn-lang.png) 50% 50% no-repeat;
        background-size: 100% auto;
        white-space: nowrap;

        span {
            @include default-icons-after('\e014', 0 0 0 1px, 10px, inherit);

            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &:after {
                position: relative;
                top: -1px;
            }
        }
    }

    &__drop {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        font-size: 1.1rem;

        li {
            padding: 2px 8px;
        }

        a {
            position: relative;
            display: block;
            padding-bottom: 100%;
            height: 0;
            border-radius: 50%;
            background: $color-2--1;

            &:hover {
                background: lighten($color-2--1, 10%);
            }
        }

        span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:hover &__drop {
        display: block;
    }
}