// @name Pager list
.pager {
    ul {
        font-family: $typo-2;
        clear: both;
        display: block;
        margin: 3em 0 5em 0;
        padding: 0;
        text-align: center;
        font-weight: $light;
        font-style: normal;
        text-transform: uppercase;
    }
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 em(0, 1.4);
        padding: 0;
        background: none;
        font-size: 1.4em;
        a, span {
            padding: 13px 14px 11px;
            line-height: 1em;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50%;
            width: 38px;
        }
        a {
            color: $color-1--1;
            background: $color-2--1;
            text-decoration: none;
            transition: all ease .2s;
            &:hover, &:focus {
                color: color-contrast($color-1--3);
                background: $color-black;
            }
        }
        span {
            cursor: default;
            color: color-contrast($color-1--2);
            background: $color-black;
        }
        &.pager__prev, &.pager__next {
            a, span {
                border-radius: 0;
                padding: 1.7rem 3.5rem 1.5rem;
                width: auto;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 30px;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    width: 2.9rem;
                    height: 2.9rem;
                    background: #111212;
                    border-radius: 50%;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 37px;
                    font-family: "icons-default";
                    content: "\e026";
                    font-size: 14px;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    color: $color-white;
                }
            }
            a {
                color: #111212;
                &:hover, &:focus {
                    background: #555;
                    color: #fff;
                    &:before {
                        background: $color-white;
                    }
                    &:after {
                        color: #111212;
                    }
                }
            }
            // Inactive
            span {
                color: color-contrast($color-3--1);
                background: $color-3--1;
                &:before {
                    background: $color-3--1;
                }
            }
        }
        &.pager__prev {
            float: left;
            a, span {
                float: left;
                padding-left: 6.4rem;
            }
            span {
                color: $color-3--2;
                // Inactive
                &:before, &:hover:before, &:focus:before {
                    color: $color-3--2;
                }
            }
        }
        &.pager__next {
            float: right;
            a, span {
                float: right;
                padding-right: 6.4rem;
                &:before {
                    left: auto;
                    right: 30px;
                }
                &:after {
                    left: auto;
                    right: 37px;
                    content: "\e027";
                }
            }
            span {
                color: $color-3--2;
                // Inactive
                &:after, &:hover:after, &:focus:after {
                    color: $color-3--2;
                }
            }
        }
    }
}

// @name Pager infinite scroll

@keyframes spin {
    0% {
        transform: rotate(0deg) translateY(-50%);
    }
    100% {
        transform: rotate(-360deg) translateY(-50%);
    }
}

.pager-infinite {
    margin: 2em 0;
    text-align: center;
    .button-2.pager-infinite__button {
        padding: 1.1rem 3.2rem 0.7rem;
        &:hover {
            .ico {
                background: #fff;
            }
        }
    }
    // @dependence .button-2
    .pager-infinite__button {
        .infinite {
            &:before {
                content: "\e05f";
            }
        }
    }
    .pager-infinite__loading {
        .infinite {
            &:before {
                animation: spin 1s infinite linear;
                content: "\e05f";
                transform-origin: top;
            }
        }
    }
}

// @name Pager single
// @dependence .pager
.pager-single {
    .pager-single__back-to-list {
        a {
            border-radius: 0;
            background: $color-1--2;
            color: color-contrast($color-1--2);
            font-weight: $normal;
            padding: 1.6rem 2.7rem 1.6rem 6.3rem;
            width: auto;
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: 24px;
                top: 50%;
                transform: translate(0, -50%);
                width: 29px;
                height: 29px;
                background: $color-2--1;
                border-radius: 50%;
            }
            &:after {
                content: "\e02f";
                font-family: "icons-default";
                position: absolute;
                top: 50%;
                left: 31px;
                transform: translate(0, -50%);
                font-size: 1.4rem;
                color: #000;
            }
            &:hover {
                background: $color-2--1;
                &:before {
                    background: $color-white;
                }
            }
        }
    }
}

// @name Pager single title
// TODO

// @name Pager alphabet
.pager-alphabet {
    margin-bottom: 5em;
}

.pager-alphabet__title {
    font-size: 1.4em;
    font-weight: $normal;
    margin-bottom: em(1.5, 1.4);
}

.pager-alphabet__listitems {
    text-align: left;
    > li {
        display: inline-block;
        vertical-align: middle;
        margin: .2em 0.1em;
        &:last-child {
            a {
                font-size: 1.2rem;
            }
        }
        &.pager-alphabet__item--current {
            > a {
                background: $color-1--2;
                color: $color-white;
            }
        }
        > a, > span {
            $pager-alphabet__listitems__a__font-size: 1.4;
            font-size: #{$pager-alphabet__listitems__a__font-size}em;
            background: $color-3--1;
            color: $color-black;
            display: block;
            padding: 8px 0;
            text-align: center;
            font-family: $typo-2;
        }
        > a {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            &:hover, &:focus {
                background: $color-2--1;
                text-decoration: none;
            }
        }
        > span {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background: $color-white;
            border: 1px solid darken($color-3--1, 3%);
            color: darken($color-3--1, 15%);
        }
    }
}

