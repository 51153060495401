.project-directives,
.content .project-directives {
    max-width: 750px;
    margin: 0 auto 35px;
    padding: 51px 0 0 169px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;
    &:before {
        transform: translate(0, 0);
        top: 99px;
        left: 328px;
        width: 999px;
    }
    &:after {
        visibility: visible;
        position: absolute;
        bottom: 37px;
        right: 130px;
        width: 999px;
        height: 109px;
        background: url(../Images/bg/plus-pattern.png);
        z-index: -1;
    }
    &__item {
        float: none;
        &_lead {
            left: -2px;
            top: 23px;
            transform: translate(0, 0);
        }
    }
    a {
        &:nth-of-type(1) {
            margin-left: 50px;
        }
        &:nth-of-type(2) {
            margin-right: 50px;
        }
    }
}