// @name Go so far
// @description Sections content below the content

.go-so-far {
    margin: 0 -1rem;
    padding: 1.9em 0 0;
    &__item {
        width: 100% !important;
        padding-bottom: 34px;
    }
}