.technologies {
    &-carousel {
        .listItems {
            margin: 0 2rem;
        }
        .item {
            padding: 0 0.2rem;
            svg {
                margin: 0 0 0.6rem;
            }
        }
    }
}