// @name Main menu
.menu-main {
    float: left;
    margin: 0 34px 0 0;
    &__level-1 {
        @extend .clear-fix;
        > li {
            float: left;
            > a {
                @extend .clear-fix;
                display: block;
                font: 1.8rem/2.7rem oswald, arial, helvetica, sans-serif;
                color: $color-black;
                text-transform: uppercase;
                text-align: center;
                padding: 1.1rem 0.6rem;
                &:hover {
                    text-decoration: none;
                }
                .ico {
                    @include default-icons-after('\e014', 0, 16px, $color-2--1);
                    position: relative;
                    float: left;
                    width: 3.1rem;
                    height: 2.7rem;
                    border-right: 1px solid #ccc;
                    &:after {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .text {
                    float: left;
                    padding: 0 1.3rem;
                }
                &.active {
                    background: $color-2--1;
                    .ico {
                        border-right-color: $color-black;
                        &:after {
                            color: $color-black;
                        }
                    }
                }
            }
            &.active > a,
            > a:hover {
                background: $color-2--1;
                .ico {
                    border-right-color: $color-black;
                    &:after {
                        color: $color-black;
                    }
                }
            }
        }
    }
    &__drop {
        display: none;
        position: absolute;
        z-index: 102;
        left: 0;
        right: 0;
        top: 100%;
        background: $color-3--1;
        border: 10px solid $color-2--1;
        width: 100%;
        max-width: 122rem;
        padding: 6.8rem 4.4rem;
        color: $color-1--1;
        font: 300 1.3rem/1.1 Montserrat, arial, helvetica, sans-serif;
        a {
            color: inherit;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__level-1 .active &__drop {
        display: block;
    }
    &__level-1 .active > a {
        position: relative;
        z-index: 102;
    }
    &__col {
        display: inline-block;
        vertical-align: top;
        min-height: 1px;
        width: 33%;
        padding: 0 3rem 0 0;
        margin-bottom: 3rem;
    }
    &__section {
        &:not(:last-child) {
            margin: 0 0 3.9rem;
        }
    }
    &__section-title {
        position: relative;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: top;
        font: 400 1.6rem/1 oswald, arial, helvetica, sans-serif;
        padding: 0 0 2rem;
        margin: 0 0 1.5rem;
        color: $color-1--1;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: -3px;
            height: .6rem;
            background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
        }
    }
    &__section-links {
        a {
            display: inline-block;
            vertical-align: top;
            position: relative;
            padding: .2rem 1.1rem .2rem 2.1rem;
            color: $color-1--1;
            &:before {
                content: '';
                position: absolute;
                left: 1.1rem;
                top: .6rem;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 3px 0 3px 6px;
                border-color: transparent transparent transparent $color-1--1;
            }
            &:hover {
                background: $color-1--1;
                color: $color-white;
                &:before {
                    border-left-color: $color-white;
                }
            }
        }
    }
    &__drop-close {
        position: absolute;
        border-radius: 50%;
        right: 1.1rem;
        top: 1.2rem;
        width: 3.8rem;
        height: 3.8rem;
        border: 3px solid $color-3--2;
        background: $color-white;
        cursor: pointer;
        padding: 0;
        overflow: hidden;
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 12px;
            background: #797979;
            margin: auto;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.menu-main-wrap {
    position: relative;
    padding: 1.4rem 9rem 0 19rem;
    @media (max-width: 1280px) {
        .menu-main {
            margin: 0 10px 0 0;
        }
        .button-1--big {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.menu-main-overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 101;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
}
