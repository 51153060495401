// # Global
$domain: "paris-saclay.com";

// ## Typography
$import-google-font-2: "https://fonts.googleapis.com/css?family=Oswald:300,400,700";
$import-google-font-3: "https://fonts.googleapis.com/css?family=Lato:400,700";
$import-google-font-4: "https://fonts.googleapis.com/css?family=Open+Sans:300,400";

$import-google-font-4: "https://fonts.googleapis.com/css?family=Open+Sans:300,400";

$typo-1: 'montserrat', sans-serif;
$typo-2: 'Oswald', sans-serif;
$typo-3: $typo-1;
$typo-4: 'Open Sans', sans-serif;

$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// ## Path
$image-bg-url: "../Images/bg/";
$image-pictos-url: "../Images/pictos/";
$image-form-url: "../Images/form/";
$image-fancybox-url: "../Images/fancybox/";
$font-path: "../Fonts/";

// ## Media queries
$smartphone: 767;
$tablet: 1220;

// ## B&w
$color-black: #000;
$color-white: #fff;
$color-red: #fe0000;
$color-green: #278417;

// ## Alert & required
$alert-background: #fcdcde;
$alert-title: #ff000d;
$alert-color: $color-black;

// ## color-1
$color-1--1: #111212;
$color-1--2: $color-1--1;
$color-1--3: $color-1--1;

// ## color-2
$color-2--1: #fcd808;
$color-2--2: $color-2--1;
$color-2--3: $color-2--1;

// ## Color-3
$color-3--1: #f2f2ef;
$color-3--2: #d3d3d3;
$color-3--3: #777777;
