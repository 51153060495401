.content-gallery {
    position: relative;
    margin: 0 0 3.3rem;

    .listItems {
        margin: 0 4rem;
    }

    .item {
        white-space: normal;
    }

    .item-inner {
        overflow: hidden;
        position: relative;
        padding-bottom: (383 / 1140 * 100%);
        height: 0;
        background: $color-3--3;
    }

    .item-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .item-content {
        position: absolute;
        z-index: 2;
        width: 47.5rem;
        height: 100%;
        right: 0;
        top: 0;
        text-align: center;
        color: $color-1--1;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 40%;
            transform: translateY(-50%);
            width: 58.5rem;
            height: 58.5rem;
            background: rgba(232, 200, 14, .8);
            border-radius: 50%;
        }
    }

    .item-content-inner {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 2rem 4rem;
        .button-2 {
            &:hover,
            &:focus {
                .ico {
                    background: #000;
                    .plus {
                        &:before,
                        &:after {
                            background: #fcd808;
                        }
                    }
                }
            }
        }
    }

    .item-subtitle {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 1.2rem;
        line-height: 1;
        margin: 0 0 1.6rem;
    }

    .item-title {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 3.2rem;
        line-height: (36 / 32);
        margin: 0 0 2rem;

        a {
            padding: 0;
            background: none;
            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 2500px;
                height: 2500px;
                content: '';
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .item-person {
        font-size: 1.1rem;
        line-height: 1.1;
        margin: 0 0 2.4rem;

        strong {
            font-weight: $bold;
            display: block;
        }
    }

    .item-decore {
        position: absolute;
        z-index: 1;
        left: 50%;
        background-size: 100% auto;
    }

    .item-decore-1 {
        width: 13.5rem;
        height: 13.5rem;
        transform: translate(-50%, -50%);
        background-image: url(#{$image-pictos-url}decore-circle-4.png);
        margin: 0 0 0 -3rem;
    }

    .item-decore-2 {
        bottom: -5.1rem;
        background-image: url(#{$image-pictos-url}decore-circle.png);
        margin: 0 0 0 -5.3rem;
        width: 31rem;
        height: 31rem;
    }

    .prevNextCaroussel3 {
        margin: 0;
        padding: 0;
    }

    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: 0;

        &:before {
            display: none;
        }

        img {
            display: none;
        }

        button {
            @include default-icons-before('', 0, 2.4rem, $color-1--1);
        }
    }

    .prevCaroussel3 {
        left: 0;

        button {
            &:before {
                content: '\e010';
            }
        }
    }

    .nextCaroussel3 {
        right: 0;

        button {
            &:before {
                content: '\e011';
            }
        }
    }
}