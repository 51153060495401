.hero {
    position: relative;
    z-index: 1;
    overflow: visible;
    &__img {
        height: 20.6rem;
    }
    &__skew,
    &__lang {
        display: none;
    }
    &__gallery {
        height: auto;
        .wrapper__content {
            width: 33.2em;
            height: 33.2em;
            padding: 8.8rem 4.6rem;
            &:before {
                right: -3.8rem;
                top: -4.2rem;
            }
            .category {
                font-size: 1.6rem;
            }
            .title {
                font-size: 2.2rem;
            }
            .moreInfos {
                bottom: 1rem;
                right: 4.5rem;
            }
        }
        .wrapper {
            max-width: none;
            padding: 0;
            left: -3em;
            bottom: -3.2rem;
        }
        .item {
            padding-bottom: 50.2%;
            &-video {
                iframe {
                    height: 100%;
                }
            }
        }
    }
}