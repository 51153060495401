// @name Cross menu
.menu-cross {
	text-transform: uppercase;
	width: 180px;
	float: right;
	margin: -23px 0 0;

    ul {
        margin: 0 0 10px;
    }

    li {
        margin: 0 0 2px;
        a {
            font-size: 1.2rem;
            line-height: 1.2;
            font-weight: 400;
            color: #fff;
            position: relative;
            display: inline-block;
            vertical-align: top;
            padding: 3px 10px 3px 13px;
            font-family: $typo-2;
            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                font-family: icons-default;
                font-size: 10px;
                line-height: 1;
                font-weight: 400;
                color: #fcd808;
                content: '\e01b';
            }
        }
        &.active a {
            color: #fcd808;
            text-decoration: none;
            font-weight: $bold;
        }
    }
    .menu-cross-important {
        a {
            background: #fcd808;
            color: #111212;
            padding: 3px 10px 3px 17px;
            &:after {
                color: #111212;
                left: 5px;
                font-size: 11px;
            }
        }
    }
}