.section {
    padding: 4rem 0 6rem;
    &__header {
        margin: 0 0 2.2rem;
        .section__header__social {
            margin: 13px 0 -6px;
        }
    }
}

.section__title,
.content .section__title {
    font-size: 3rem;
    margin: 0 -110px;
    padding: 0;
    &:before {
        left: - 14rem;
    }
    &:after {
        right: -14rem;
    }
    .txt {
        max-width: 36rem;
    }
}