.ddm.ddm__content {
    margin: 5em 0 4em;
    .ddm__button {
        font-size: 1.6rem;
        padding: 1.3rem 5rem 1.3rem 2.1rem;
    }
    &.ddm--active .ddm__sub-level {
        padding: 2.7rem 1rem;
        display: block;
    }
}