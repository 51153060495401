// @name Title 1
// @description Styling for title 1
.title-1 {
    $title--1__font-size: 3.2;
    font-size: #{$title--1__font-size}em;
    margin: em(2.5, $title--1__font-size) 0 em(1.5, $title--1__font-size);
}

.title-2 {
    padding: 5px 18px;
    font-size: 1.6rem;
    margin: 0 0 14px;
}

.title-3 {
    margin-bottom: 21px;
}

.decor-title {
    &-ico {
        width: 44px;
        height: 44px;
        margin: 0 7px 0 0;
    }
    h2 {
        font-size: 2.4rem;
        line-height: 1.2;
    }
    &-c {
        &:before {
            left: -145px;
        }
        &:after {
            right: -145px;
        }
    }
}