.section {
    padding: 6rem 0;

    &__header {
        position: relative;
        padding: 0 5rem;
        text-align: center;
        margin: 0 0 3.6rem;

        .rss {
            @include default-icons-absolute-before('\e02c', 1.2rem, $color-3--3, 50%, auto, auto, 50%);
            position: absolute;
            right: 0;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            border: 3px solid $color-3--1;
            width: 3.8rem;
            height: 3.8rem;
            color: $color-3--3;
            background: $color-white;
            padding: 0;
            overflow: hidden;
            transition: all ease .3s;
            &:before {
                transition: all ease .3s;
                transform: translate(-50%, -50%);
                font-weight: $normal;
            }
            &:hover, &:focus {
                background: $color-black;
                border-color: $color-black;
                &:before {
                    color: $color-white;
                }
            }
        }
        .section__header__social {
            display: flex;
            justify-content: center;
            margin: 2px 0 -26px;
            li {
                padding: 0 1px;
                margin: 0;
                &:before {
                    display: none;
                }
                &.active {
                    a {
                        border-color: #000;
                        background: #000;
                        color: #fff;
                    }
                }
            }
            a {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                border: 3px solid #f2f2ef;
                border-radius: 50%;
                background: none;
                position: relative;
                font-size: 1.1rem;
                line-height: 1.2;
                text-transform: uppercase;
                font-family: $typo-2;
                color: #777;
                svg {
                    fill: #777;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:hover,
                &:focus {
                    border-color: #000;
                    background: #000;
                    svg {
                        fill: #fff;
                    }
                }
            }
        }
    }

    &__title,
    .content &__title {
        position: relative;
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 3.4rem;
        line-height: 1;
        font-family: $typo-1;
        white-space: nowrap;
        padding: 0 16.2rem;
        margin: 0;
        display: inline-block;
        vertical-align: top;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: .6rem;
            width: 13rem;
            background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }

        .txt {
            white-space: normal;
            max-width: calc(100% - 6rem);
        }

        .ico {
            position: relative;
            margin: 0 0.3rem 0 0;
            border-radius: 50%;
            background: $color-2--1;
            width: 5.2rem;
            height: 5.2rem;

            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                stroke: $color-1--1;
                stroke-width: 4px;
            }

            &_white {
                background: $color-white;
            }
        }

        &_white {
            color: $color-white;

            &:before,
            &:after {
                background-image: url(#{$image-bg-url}sep-2.png);
            }
        }
    }
}