.agenda {
    &__list {
        margin: 0 0 1rem;
        &-item {
            width: 100%;
            float: none;
            padding: 0;
            margin: 0 0 20px;
        }
    }
}