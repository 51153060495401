.interactive-map {
    position: relative;
    color: $color-1--1;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 26rem;
        background: $color-2--1;
    }
    &__inner {
        @extend .clear-fix;
        margin: 0;
        display: flex;
        flex-wrap: nowrap;
        > * {
            align-content: flex-start;
            align-items: flex-start;
        }
    }
    &__info,
    &__territory,
    &__communes {
        min-height: 1px;
    }
    &__info {
        flex-basis: (240 / 1220 * 100%);
        order: 1;
    }
    &__info-unit {
        font-weight: $light;
        font-size: 1.3rem;
        line-height: 1.1;
        text-align: right;
        max-height: 100em;
        transition: all ease .3s .3s;
        height: auto;
        overflow: hidden;
        &.hide {
            max-height: 0;
            transition: all ease .3s;
        }
        p {
            margin: 0 0 1.6rem;
            font-size: inherit;
        }
    }
    &__info-unit-title {
        text-transform: uppercase;
        display: block;
        margin: 0 0 2.9rem;
        font-size: 1.5rem;
        line-height: 1.2;
        padding: 1.6rem 0 0;
        min-height: 11.2rem;
        position: relative;
    }
    &__info-unit-content {
    }
    &__communes {
        flex-basis: (354 / 1220 * 100%);
        order: 3;
    }
    &__communes-title,
    .content &__communes-title {
        text-transform: uppercase;
        font-weight: $bold;
        font-size: 3.5rem;
        line-height: 0.85;
        font-family: $typo-1;
        margin: 0 0 2.1rem;
        min-height: 11.2rem;
        position: relative;
        .num {
            font-size: 7.1rem;
        }
    }
    &__communes-list,
    .content &__communes-list {
        font-size: 0;
        margin: 0;
        padding: 0;
        a {
            background: none;
            padding: 0;
            color: inherit;
            &:hover,
            &.active {
                text-decoration: underline;
                font-weight: bold;
            }
        }
        li {
            text-transform: uppercase;
            font-weight: $light;
            font-size: 1.6rem;
            line-height: 1.1;
            font-family: $typo-2;
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding-left: 1.5rem;
            margin: 0 0 .4rem;
            &:before {
                content: '\e011';
                font-size: 1.6rem;
                top: .3rem;
            }
            &:nth-child(odd) {
                padding-right: .5rem;
            }
        }
    }
    &__territory,
    .content &__territory {
        flex-basis: (626 / 1220 * 100%);
        padding: 0 4rem;
        position: relative;
        order: 2;
        svg {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: auto;
        }
        path:first-child {
            pointer-events: none;
        }
        path:not(:first-child) {
            fill: transparent;
            cursor: pointer;
            &.active {
                fill: $color-1--1;
            }
        }
    }
    &__territory-wrap,
    .content &__territory-wrap {
        position: relative;
        margin: 0;
        height: 100%;
        display: inline-block;
        width: 100%;
        padding-bottom: 89.6%; // (336.801/375.501)*100 - (heightSvg/widthSvg)*100
        vertical-align: middle;
        overflow: hidden;
        &:before,
        &:after {
            content: '';
            position: absolute;
            background-origin: content-box;
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: 50% 0;
        }
        &:before {
            bottom: 2rem;
            left: 50%;
            transform: translateX(-50%);
            width: 45.1rem;
            height: 45.1rem;
            background-image: url(#{$image-pictos-url}decore-circle-5.png);
        }
        &:after {
            background-image: url(../Images/map/map-shadow.png);
            left: -4px;
            right: -4px;
            top: -4px;
            bottom: -4px;
        }
    }
}

.interactive-map-tooltip {
    position: absolute;
    z-index: 101;
    text-transform: uppercase;
    font-weight: $light;
    font-size: 1.3rem;
    line-height: 1;
    font-family: $typo-2;
    padding: 1rem 2rem;
    background: $color-1--1;
    pointer-events: none;
    margin: 35px 0 0;
    color: $color-white;
    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 0 0 10px;
        border-color: transparent transparent transparent $color-1--1;
    }
}
