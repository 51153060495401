.ddm.ddm__content + .ddm.ddm__content {
    margin-top: -3.9em;
}

.ddm.ddm__content {
    margin: 4em 0 4em 0;
    .ddm__title {
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 2.2rem;
        font-weight: $light;
    }
    .ddm__sub-level {
        position: static;
        top: inherit;
        right: inherit;
        z-index: inherit;
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: all .2s;
        background: #f2f2ef;

        > :last-child {
            margin-bottom: 0 !important;
        }
    }
    &.ddm--active {
        .ddm__sub-level {
            padding: 3.3rem 2rem;
            max-height: 100%;
            transition: all ease-in-out 1s;
            display: inline-table;
        }
    }
}

.ddm__content {
    .ddm__button {
        display: block;
        width: 100%;
        text-align: left;
        font-size: 1em;
        font-family: $typo-3;
        font-weight: $light;
        background: $color-1--1;
        padding: 0.8rem 5rem 0.8rem 2.1rem;
        position: relative;
        transition: all ease .3s;
        white-space: normal;
        color: $color-white;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0.7rem;
            transform: translateY(-50%);
            width: 3.2rem;
            height: 3.2rem;
            background: $color-1--1;
            z-index: 1;
            border-radius: 50%;
            border: 2px solid #555;
        }
        @include default-icons-absolute-before('\e000', 0.8rem, $color-white, 50%, 1.9rem, inherit, inherit);
        &:before {
            z-index: 2;
            transform: translateY(-50%);
        }
    }
    &.ddm--active {
        .ddm__button {
            background: $color-2--2;
            color: $color-1--1;
            &:before {
                content: "\e001";
                font-size: 0.6rem;
                color: $color-1--1;
                right: 2rem;
            }
            &:after {
                background: $color-white;
                border: 0;
            }
        }
    }
}
