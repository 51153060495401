// @name buttons

// @name button-1 (light)
// @state button-1--big - Big button-1
// @state button-1--small - Small button-1
// @state .button-1--no-icon - Button-1 without icon
// @state .button-1--svg - Button-1 with svg content
// @state .button-1--no-text-in-mobile - Indicates button-1 without content text in smartphone view

$button-1__font-size: 1.4;
$button-1--big__font-size: 1.8;
$button-1--small__font-size: 1.2;
input.button-1, .content input.button-1 {
  padding-left: em(3, $button-1__font-size);
  &.button-1--big {
    padding-left: em(3, $button-1--big__font-size);
  }
  &.button-1--small {
    padding-left: em(2.2, $button-1--small__font-size);
  }
}
.button-1, .content .button-1 {
  font-family: $typo-2;
  display: inline-block;
  vertical-align: middle;
  transition: all ease .3s;
  text-decoration: none;
  font-weight: $normal;
  color: $color-white;
  text-transform: uppercase;
  background: $color-1--1;
  font-size: #{$button-1__font-size}em;
  line-height: em(1, $button-1__font-size);
  padding: em(1.8, $button-1__font-size) em(3, $button-1__font-size) em(1.8, $button-1__font-size) em(2.5, $button-1__font-size);
  @include default-icons-absolute-before('', em(1.4, $button-1__font-size), $color-2--1, 50%, inherit, inherit, em(2.2, 1.4));
  &:before {
    transform: translateY(-50%);
    transition: all ease .3s;
  }
  &[type=reset] {
    &:before {
      content: "\e002";
    }
  }
  &:hover, &:focus {
    text-decoration: none;
    background: $color-2--1;
    color: color-contrast($color-white);
    .ico{
      background: $color-white;
    }
    &:before {
      color: color-contrast($color-white);
    }
    svg {
      fill: color-contrast($color-white);
    }
  }
  // Big
  &.button-1--big {
    // Change font size reference
    font-size: #{$button-1--big__font-size}em;
    padding: 19px 30px 17px;
    &:before {
      left: em(2.4 ,$button-1--big__font-size);
    }
  }
  // Small
  &.button-1--small {
    // Change font size reference
    font-size: #{$button-1--small__font-size}em;
    padding: em(.7, $button-1--small__font-size) em(2.2, $button-1--small__font-size) em(.7, $button-1--small__font-size) em(1.5, $button-1--small__font-size);
    &:before {
      left: em(1.5 ,$button-1--small__font-size);
    }
    .ico2{
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  // No icon
  &.button-1--no-icon {
    padding-left: em(2, $button-1__font-size);
    padding-right: em(2, $button-1__font-size);
    &:before {
      content: "";
      margin: 0;
    }
    &:after{
      display: none;
    }
  }
  // No text
  &.button-1--no-text {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: em(1.3, $button-1__font-size) em(1.9, $button-1__font-size);
    &:before {
      text-indent: 0;
      line-height: 0;
      display: block;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      margin: 0;
      content: '\e026';
    }
  }
  // With svg
  &.button-1--svg {
    padding: em(1.4, $button-1__font-size) em(1.3, $button-1__font-size);
  }
  svg {
    fill: $color-2--2;
    width: em(1.2, $button-1__font-size);
    height: em(1.2, $button-1__font-size);
    vertical-align: middle;
  }
  // With calendar font-icon
  &.button-1--calendar {
    &:before {
      left: em(1, 1.4);
      content: "\e05c";
    }
  }
  .ico2 {
    border-radius: 50%;
    background: $color-2--1;
    width: 2.9rem;
    height: 2.9rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    left: 0;
    top: -2px;
    margin: -1.5rem 1rem -1.5rem 0;
    line-height: 2.9rem;
    text-align: center;
    font-style:normal;

    svg,
    img,
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto !important;
    }

    svg {
      fill: #111212;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .arrow {
      &:before{
        font-family: "icons-default";
        content: "\e026";
        margin-right: 5px;
        font-size: 14px;
        color: inherit;
        vertical-align: middle;
        display: inline-block;
      }
    }
  }
}
//  @name Extenral link for button-1
a.button-1[href^="http://"]:not([href*="#{$domain}"]),
a.button-1[href^="https://"]:not([href*="#{$domain}"]),
a.button-1[href^="//"]:not([href*="#{$domain}"]) {
  @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-black, middle);
  &:after {
    transition: all ease .3s;
  }
  &:hover, &:focus {
    &:after {
      color: $color-white;
    }
  }
}

// @name button-2 (Dark)
// @state button-2--big - Big button-2
// @state button-2--small - Small button-2
// @state .button-2--no-icon - Button-2 without icon
// @state .button-2--svg - Button-2 with svg content
// @state .button-2--no-text-in-mobile - Indicates button-2 without content text in smartphone view

$button-2__font-size: 1.4;
$button-2--big__font-size: 1.9;
$button-2--small__font-size: 1.2;
input.button-2, .content input.button-2 {
  padding-left: em(3.2, $button-2__font-size);
  &.button-2--big {
    padding-left: em(3.2, $button-2--big__font-size);
  }
  &.button-2--small {
    padding-left: em(2.2, $button-2--small__font-size);
  }
}
.button-2, .content .button-2 {
  font-family: $typo-2;
  display: inline-block;
  vertical-align: middle;
  transition: all ease .3s;
  text-decoration: none;
  font-weight: $normal;
  color: $color-white;
  text-transform: uppercase;
  background: $color-1--2;
  font-size: 1.3rem;
  line-height: 1;
  padding: 1.7rem 2.5rem;
  padding: em(1.8, $button-1__font-size) em(3, $button-1__font-size) em(1.8, $button-1__font-size) em(2.5, $button-1__font-size);
  @include default-icons-absolute-before('', em(1.4, $button-2__font-size), $color-2--1, 50%, inherit, inherit, em(2.2, 1.4));
  &:before {
    transform: translateY(-50%);
    transition: all ease .3s;
  }
  &:hover, &:focus {
    text-decoration: none;
    background: $color-white;
    color: color-contrast($color-white);
    svg {
      fill: color-contrast($color-white);
    }
    .ico2{
      background: #555;
    }
  }
  // Big
  &.button-2--big {
    // Change font size reference
    font-size: #{$button-2--big__font-size}em;
    padding: em(1.9, $button-1--big__font-size) em(3, $button-1--big__font-size) em(1.9, $button-1--big__font-size) em(2.5, $button-1--big__font-size);
    &:before {
      left: em(2.4 ,$button-2--big__font-size);
    }
  }
  // Small
  &.button-2--small {
    // Change font size reference
    font-size: #{$button-2--small__font-size}em;
    padding: em(.7, $button-1--small__font-size) em(2.2, $button-1--small__font-size) em(.7, $button-1--small__font-size) em(1.5, $button-1--small__font-size);
    &:before {
      left: em(1.5, $button-2--small__font-size);
    }
    .ico2{
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  // No icon
  &.button-2--no-icon {
    padding-left: em(2, $button-2__font-size);
    padding-right: em(2, $button-2__font-size);
    &:before {
      content: "";
      margin: 0;
    }
  }
  // No text
  &.button-2--no-text {
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    position: relative;
    padding: em(1.3, $button-2__font-size) em(1.9, $button-2__font-size);
    &:before {
      text-indent: 0;
      line-height: 0;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      margin: 0;
      font-size: em(1.4, $button-2__font-size);
      content: '\e057';
    }
  }
  // With svg
  &.button-2--svg {
    padding: em(1.4, $button-2__font-size) em(1.3, $button-2__font-size);
  }
  // With next icon
  &.button-2--next {
    &:before {
      content: "\e027";
    }
  }
  // With calendar font-icon
  &.button-2--calendar {
    &:before {
      left: em(1, 1.4);
      content: "\e05c";
    }
  }

  &.button-2_ico {
    padding: 0 2.5rem;
    padding-top: 1.1rem;
    padding-bottom: 0.7rem;

    &:before {
      content: none !important;
    }
  }

  &.button-2_inverse {
    background: $color-white;
    color: $color-1--1;

    &:hover {
      background: $color-1--1;
      color: $color-white;
    }
  }
  svg {
    fill: $color-2--2;
    width: em(1.2, $button-1__font-size);
    height: em(1.2, $button-1__font-size);
    vertical-align: middle;
  }

  .ico {
    border-radius: 50%;
    background: $color-2--1;
    width: 2.9rem;
    height: 2.9rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    left: 4px;
    top: -1px;
    margin: 0 0.7rem 0 0;
    line-height: 2.9rem;
    text-align: center;
    font-style:normal;

    svg,
    img,
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto !important;
    }

    svg {
      fill: #111212;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plus {
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $color-1--1;
      }

      &:before {
        width: 11px;
        height: 1px;
      }

      &:after {
        width: 1px;
        height: 11px;
      }
    }
    .infinite {
      &:before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        color: $color-1--1;
        font-family: "icons-default";
        font-size: 14px;
      }
    }
    .check {
      @include default-icons-before('\e059', 0, 1.6rem, inherit);

      &:before {
        position: relative;
        left: -2px;
      }
    }

  }
  .ico2 {
    border-radius: 50%;
    background: $color-2--1;
    width: 2.9rem;
    height: 2.9rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    left: 0;
    top: -2px;
    margin: -1.5rem 1rem -1.5rem 0;
    line-height: 2.9rem;
    text-align: center;
    font-style:normal;

    svg,
    img,
    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto !important;
    }

    svg {
      fill: #111212;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .arrow {
      &:before{
        font-family: "icons-default";
        content: "\e026";
        margin-right: 5px;
        font-size: 1.4rem;
        color: inherit;
        vertical-align: middle;
        display: inline-block;
      }
    }
    .arrow-right{
      &:before{
        font-family: "icons-default";
        content: "\e027";
        margin-left: 5px;
        font-size: 14px;
        color: inherit;
        vertical-align: middle;
        display: inline-block;
      }
    }

  }
  &.button-2__yellow {
    background: $color-2--1;
    color: $color-1--1;

    .ico {
      background: $color-1--1;

      svg {
        fill: $color-2--1;
      }

      .plus {
        &:before,
        &:after {
          background: $color-2--1;
        }
      }
    }
    &:hover,
    &:focus {
      background: #555;
      color: #fff;
      .ico {
        background: #fff;
        span {
          &:before {
            color: #000;
          }
        }
        svg {
          fill: #000;
        }
      }
    }
  }
}
//  @name Extenral link for button-2
a.button-2[href^="http://"]:not([href*="#{$domain}"]),
a.button-2[href^="https://"]:not([href*="#{$domain}"]),
a.button-2[href^="//"]:not([href*="#{$domain}"]) {
  @include default-icons-after('\e086', 0 0 0 5px, 8px, $color-white, middle);
  &:after {
    transition: all ease .3s;
  }
}

// @name Grey form
// @description Form with grey background
.form--3 {
   // @name button-1 (light)
  .button-1 {
    background: $color-white;
    &:hover, &:focus {
      background: $color-1--3;
      text-decoration: none;
    }
  }
   // @name button-1 (light)
  .button-1 {
    background: $color-white;
    &:hover, &:focus {
      background: $color-1--3;
      text-decoration: none;
    }
  }
}

.btn-search {
  position: absolute;
  right: 3rem;
  top: 0.7rem;
  padding: 0;
  background: url(#{$image-pictos-url}btn-search.png) 0 0 no-repeat;
  width: 6.7rem;
  height: 6.7rem;

  svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1.6rem;
    height: auto;
    margin: auto;
  }
}

.btn-menu {
  width: 4.9rem;
  height: 4.9rem;
  border-radius: 50%;
  background: $color-2--1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  display: none;

  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 13px;
    background: $color-1--1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
    }

    &:before {
      margin-top: -4px;
    }

    &:after {
      margin-top: 4px;
    }
  }
}

.btn-play {
  position: relative;
  width: 6.8rem;
  height: 6.8rem;
  border-radius: 50%;
  border: 1px solid rgba(236, 202, 33, .7);
  padding: 1rem;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgba(236, 202, 33, .7);
  }

  &:before {
    left: .3rem;
    right: .3rem;
    top: .3rem;
    bottom: .3rem;
  }

  &:after {
    left: .7rem;
    right: .7rem;
    top: .7rem;
    bottom: .7rem;
  }

  span {
    @include default-icons-absolute-before('\e075', 1.8rem, $color-1--1, 50%, auto , auto, 50%);

    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: $color-2--1;

    &:before {
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}

.btn-plus {
  display:inline-block;
  vertical-align:top;
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fcd808;
  &:hover,
  &:focus {
    background: #211f11;
    &:after,
    &:before {
      background: #fff;
    }
  }
  &:after,
  &:before {
    background: #211f11;
    width: 11px;
    height: 1px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    content: '';
  }
  &:after {
    width: 1px;
    height: 11px;
  }
}
.align-center {
  .button-2 {
    .ico {
      transition: all ease .3s;
    }
    .plus {
      &:before,
      &:after {
        transition: all ease .3s;
      }
    }
    &:hover,
    &:focus {
      .ico {
        background: #555;
        svg {
          fill:#fff;
        }
        .plus {
          &:before,
          &:after {
            background: #fff;
          }
        }
      }
    }
    &__yellow {
      &:hover,
      &:focus {
        background: #555;
        color: #fff;
        .ico {
          .plus {
            &:before,
            &:after {
              background: #000;
            }
          }
        }
      }
    }
  }
}

.buttons {
  &-row {
    margin: 0 -1px;
  }
  &-col {
    width: 50%;
    float: left;
    padding: 0 1px;
    a {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      padding: 13px 19px 13px 39px;
      font-size: 1.3rem;
      line-height: 1;
      height: 40px;
      &:after {
        color: #fcd808 !important;
      }
      &:hover,
      &:focus {
        &:before,
        &:after {
          color: #111212 !important;
        }
      }
    }
  }
}
.publication__content .publication__controls {
  a {
    &:hover,
    &:focus {
      .ico {
        background: #555;
        svg {
          fill: #fff;
        }
        .plus {
          &:before,
          &:after {
            background: #fff;
          }
        }
      }
    }
    &.button-2__yellow {
      &:hover,
      &:focus {
        background: #555;
        color: #fff;
        .ico {
          svg {
            fill: #000;
          }
        }
      }
    }
  }
}