.cartographie {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    form,
    fieldset {
        margin: 0;
    }
    &-sidebar {
        width: 280px;
        background: #111212;
        color: #fff;
        padding: 14px 23px 23px;
        height: 100%;
        float: left;
        legend {
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 32px;
        }
        label {
            color: #fff;
            font-size: 1.6rem;
            margin-bottom: 17px;
        }
        select {
            background-color: transparent;
            color: #fff;
            padding: 12px 40px 12px 8px;
            background-position: 97% 50%;
            margin-bottom: 24px;
        }
    }
    &-map {
        float: right;
        height: 100%;
        position: relative;
        overflow: hidden;
        width: calc(100% - 280px);
        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        &-wrap {
            overflow: hidden;
            position: relative;
            height: 100%;
            img {
                position: absolute;
                top: 0;
                right: 0;
                min-width: 100%;
                min-height: 100%;
                max-width: none;
                width: auto;
                height: auto;
            }
        }
    }
    &-logo {
        width: 139px;
        margin: 0 0 15px;
        a {
            display: block;
        }
    }
    &-title {
        font-size: 2rem;
        line-height: 1.2;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0 0 25px;
        border-bottom: 1px solid #000;
        margin-bottom: 34px;
    }
    &-filter-buttons {
        margin: 0 -5px 30px;
        padding: 7px 0 0;
        display: block;
        overflow: hidden;
        width: auto !important;
        li {
            width: 50%;
            float: left;
            padding: 0 5px;
        }
        .filters__reset,
        .filters__submit {
            width: 100% !important;
            height: auto;
            padding: 13px 10px 13px 46px;
            line-height: 1;
            border: none;
            &:before {
                left: 12px;
            }
            &:after {
                left: 21px;
                font-weight: normal;
            }
        }
        .filters__submit {
            background: #fff;
            color: #111212;
        }
    }
    .pager-single {
        .pager-single__back-to-list {
            display: block;
            a {
                background: #fff;
                color: #111212;
                display: block;
                width: 100% !important;
                padding: 16px 20px 14px 70px;
                text-align: left;
                max-width: none;
                &:after {
                    font-size: 1.1rem;
                    left: 33px;
                }
                &:before {
                    left: 24px;
                }
                &:hover,
                &:focus {
                    background: #fcd808;
                }
            }
        }
    }
    &-results {
        width: 220px;
        background: #fed82d;
        float: left;
        height: 100%;
        transition: margin 0.3s linear;
        margin: 0 0 0 -220px;
        &-opened {
            .cartographie-results {
                margin: 0;
            }
        }
        &-inner {
            padding-top: 52px;
            position: relative;
        }
        &-title {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 700;
            display: block;
            margin: 0 11px 8px;
            color: #000;
        }
        &-list {
            border-top: 1px solid #000;
            li {
                border-bottom: 1px solid #000;
                font-size: 1.3rem;
                line-height: 1.2;
                font-weight: 300;
                color: #000;
                padding: 12px;
            }
            strong {
                margin: 0 0 2px;
                display: block;
                font-size: 1rem;
                line-height: 1.2;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &-close {
            width: 29px;
            height: 29px;
            background: #fff;
            position: absolute;
            top: 16px;
            right: 6px;
            border-radius: 50%;
            overflow: hidden;
            &:after,
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                -ms-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 14px;
                height: 1px;
                background: #000;
                content: '';
            }
            &:before {
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
    }
    &-info {
        float: right;
        height: 100%;
        width: 300px;
        background: #fed82d;
        z-index: 2;
        position: relative;
        margin: 0 -300px 0 0;
        transition: margin 0.3s linear;
        &-opened {
            .cartographie-info {
                margin: 0;
            }
        }
        &-inner {
            padding: 60px 32px 30px;
        }
        &-close {
            width: 29px;
            height: 29px;
            background: #fff;
            position: absolute;
            top: 14px;
            right: 14px;
            border-radius: 50%;
            overflow: hidden;
            &:after,
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                -ms-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
                width: 14px;
                height: 1px;
                background: #000;
                content: '';
            }
            &:before {
                -webkit-transform: translate(-50%, -50%) rotate(45deg);
                -ms-transform: translate(-50%, -50%) rotate(45deg);
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }
        &-img {
            margin: 0 0 20px;
        }
        &-title {
            color: #111212;
            font-size: 2rem;
            line-height: 1.2;
            font-weight: 300;
            margin: 0 0 20px;
            strong {
                display: block;
                font-size: 1.6rem;
                font-weight: 700;
            }
        }
        p {
            font-size: 1.4rem;
            line-height: 1.2;
            font-weight: 300;
            margin: 0 0 16px;
        }
        &-contact {
            font-size: 1.4rem;
            line-height: 1.2;
            font-weight: 300;
            li {
                margin: 0 0 17px;
            }
            a {
                color: #111212;
                position: relative;
                padding: 0 0 0 18px;
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    font-size: 13px;
                    line-height: 1;
                    font-family: icons-default;
                    content: '';
                }
                &[href^="http://"], &[href^="https://"] {
                    &:after {
                        content: '\e089';
                    }
                }
                &[href^="mailto"], &[href^="javascript:linkTo_UnCryptMailto"] {
                    &:after {
                        top: 54%;
                        content: '\e028';
                    }
                }
                &[href^="tel"] {
                    &:after {
                        content: '\e02b';
                    }
                }
            }
        }
        &-list {
            font-size: 1.4rem;
            line-height: 1.2;
            font-weight: 300;
            margin: 0 0 28px;
            li {
                margin: 0 0 20px;
                strong {
                    display: block;
                    font-weight: 300;
                }
            }
        }
        .button-2 {
            font-size: 1.4rem;
            padding: 7px 17px 4px 8px;
            .ico {
                margin: 0 13px 0 0;
            }
            &:hover,
            &:focus {
                background: #fff;
            }
        }
    }
}