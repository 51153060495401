.event-unit {
    position: relative;
    color: $color-1--1;

    .category {
        font-size: 1.3rem;
        padding: .1rem 0.9rem;
        margin: 0 0 1.1rem;
    }

    .hour-place {
        text-align: left;
    }

    .hour {
        margin-bottom: .1rem;
    }

    &__img {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: (275 / 380 * 100%);
        margin: 0 0 1.9rem;
        background: $color-3--3;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    &__wrap {
        @extend .clear-fix;
    }

    &__date,
    .content &__date {
        width: 15.5rem;
        height: 15.5rem;
        position: relative;
        margin: -7.5rem 1rem 0 -2.5rem;
        float: left;
        font-weight: $normal;
        font-size: 1.6rem;
        line-height: 1.1;
        font-family: $typo-2;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5.3rem;
            width: 100%;
            height: 100%;
            background: url(#{$image-pictos-url}decore-circle-2.png) 0 0 no-repeat;
            background-size: 100% auto;
        }

        .day {
            font-size: 2.6rem;
        }
    }

    &__date-inner {
        position: relative;
        border-radius: 50%;
        background: $color-2--1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }

    &__date-item,
    .content &__date-item {
        margin: 0;
        font-weight: $normal;

        span {
            display: block;
        }
    }

    &__date-item + &__date-item {
        @include default-icons-absolute-before('\e017', 1.4rem, $color-1--1, 50%, auto, auto, 0.3rem);

        padding-left: 2rem;

        &:before {
            transform: translateY(-50%);
        }
    }

    &__content {
        overflow: hidden;
    }

    &__title,
    .content &__title {
        position: static;
        margin: 0;
        padding: 0;
        font-weight: $normal;
        font-size: 1.7rem;
        line-height: 1.2;

        &:before {
            display: none;
        }

        a {
            background: none;
            color: inherit;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            &:hover {
                text-decoration: underline;
                color: inherit;
            }
        }

        sup {
            font-size: 0.5em;
        }
    }
}