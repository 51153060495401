.social-list,
.content .social-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding-top: 4px;
    font-family: $typo-1;
    li {
        width: 25%;
        padding: 0 15px 30px;
        &:before {
            display: none;
        }
    }
    a {
        color: #3b5998;
        background: none;
        padding: 0;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
    .social-list-ico {
        height: 35px;
        display: flex;
        align-items: center;
        background: #3b5998;
        justify-content: center;
        svg {
            fill: #fff;
        }
    }
    &-img {
        display: block;
        margin: 0 0 21px;
        position: relative;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &-head {
        overflow: hidden;
        position: relative;
        margin: 0 0 14px;
        font-size: 1.1rem;
        line-height: 1.2;
        color: #717171;
        font-weight: 300;
        &-share {
            @include default-icons-before('\e05e', 0 5px 0 0, 14px, $color-green);
            color: #717171 !important;
        }
        &-ico {
            border: 1px solid #bbb;
            float: left;
            margin: 0 10px 0 0;
            width: 54px;
            height: auto;
            img {
                max-width: 100%;
                height: auto;
            }
        }
        &-text {
            overflow: hidden;
            padding-top: 2px;
            font-size: 1.1rem;
            line-height: 1.2;
            color: #717171;
        }
    }
    &-title {
        font-size: 1.2rem;
        font-weight: 400;
        display: block;
    }
    p {
        font-size: 1.2rem;
        line-height: 1.2;
        color: #000;
        margin: 0 0 8px;
        font-weight: 300;
    }
    &-date {
        color: #3b5998;
        font-size: 1rem;
        line-height: 1.2;
        text-transform: uppercase;
        display: block;
        font-weight: 300;
    }
    &-facebook {
        .social-list-title {
            font-size: 1.3rem;
        }
        .social-list-head-text {
            font-size: 1.2rem;
        }
    }
    &-twitter {
        .social-list {
            &-date {
                color: #00acee;
            }
            &-ico {
                background: #00acee;
            }
        }
        a {
            color: #00acee;
        }
    }
    &-instagram {
        .social-list {
            &-date {
                color: #2a5b83;
            }
            &-ico {
                background: #2a5b83;
            }
        }
        a {
            color: #2a5b83;
        }
    }
    &-linkedin {
        .social-list {
            &-date {
                color: #0177b5;
            }
            &-ico {
                background: #0177b5;
            }
        }
        p {
            color: #154452;
        }
        a {
            color: #0177b5;
        }
    }
    &-youtube {
        .social-list {
            &-date {
                color: #e52d27;
            }
            &-ico {
                background: #cd201f;
            }
        }
        a {
            color: #b31217;
        }
    }
    .btn-play {
        width: 50px;
        height: 50px;
        background: rgba(255, 255, 255, 0.5);
        &:after {
            border-width: 6px 0 6px 9px;
            border-left-color: #000;
        }
    }
}