// @name Tooltip Full CSS
// @description Not for accessibility
// @markup <a href="#" class="tooltip-css" data-tooltip="Lorem ipsum">Lorem</a>
// @markup <span class="tooltip-css" tabindex="1" data-tooltip="Lorem ipsum">Lorem</span>

.tooltip-css {
    position: relative;
    //cursor: help;
    &::before, &::after {
        position: absolute;
        display: block;
        //opacity: 0;
        z-index: -98;
        transition: all ease-out .1s;
    }
    &::before {
        transform: translateY(-100%);
        top: calc(100% + 10px);
        left: 0;
        content: attr(data-tooltip);
        speak: none;
        white-space: nowrap;
        color: $color-white;
        font-size: 1.2em;
        font-weight: $light;
        background: $color-1--2;
        padding: 10px 15px;
        opacity: 0;
    }
    &::after {
        opacity: 0;
        transform: translateY(-100%);
        top: calc(calc(100% + 10px) - 8px);
        left: 0;
        content: '';
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 0 solid transparent;
        border-bottom: 0 solid transparent;
        border-left: 8px solid $color-1--2;
    }
    &:hover, &:focus {
        text-decoration: none;
        &::before, &::after {
            z-index: 98;
            opacity: 1;
            transition: all ease-out .4s;
            transform: translateY(0);
        }
    }
    &.tooltip-css--right {
        &::before, &:after {
            left: inherit;
        }
        &::before {
            right: -15px;
        }
        &::after {
            right: 0px;
        }
    }
}