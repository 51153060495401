// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    .bloc-event__item {
        margin-bottom:2.5rem;
    }
    .bloc-event__title {
        $bloc-event__title__font-size: 1.6;
        font-family: $typo-3;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1.2;
        margin: 1.1rem 0 0.8rem;
        color: $color-1--1;

        a {
            color: $color-1--1;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }

    .date-1 {
        margin: -8.9rem 0.9rem 2.5rem;
    }

    .hour-place {
        text-align: left;
    }

    .link-view {
        margin-top: 3rem;
    }
    .link-view__feature {
        a {
            &:hover,
            &:focus {
                .ico {
                    background: #fff;

                }
            }
        }
    }
}
