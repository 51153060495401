// @name TYPO3 default content style
.ce-left .ce-gallery, .ce-column {
    float: none;
}

.ce-right .ce-gallery {
    float: none;
}

.ce-intext.ce-right .ce-gallery {
    margin-left: 0;
}

.ce-intext.ce-left .ce-gallery {
    margin-right: 0;
}

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery {
    margin-bottom: 21px;
    margin-top: 27px;
}