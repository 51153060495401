// @name Breadcrumb
.breadcrumb {
    font-size: 1.5rem;
    font-family: $typo-2;
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    text-transform: uppercase;

    p {
        font-weight: 400;
        color: $color-1--1;

        .breadcrumb__here {
            color: $color-3--3;
        }

        a {
            @include default-icons-after('\e027', 0 0 0 5px, 1rem, $color-3--3);

            font-weight: 300;
            color: $color-3--3;

            &:after {
                position: relative;
                top: -1px;
            }

            &:last-child {
                &:after {
                    color: $color-1--1;
                }
            }
        }
    }
}