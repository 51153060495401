.hero {
    &__img {
        height: 8.9rem;
    }
    &__gallery {

        .wrapper__content {
            padding: 9.2rem 4.6rem;
            &:before {
                width: 10.4rem;
                height: 10.4rem;
                right: -1.2rem;
                top: -1rem;
                background-size: cover;
            }
            .category {
                font-size: 1.7rem;
            }
            .title {
                font-size: 2.2rem;
            }
            .moreInfos {

            }
        }
        .wrapper {
            left: -6em;
            bottom: -23rem;
            z-index: 2;
        }
        .item {
            padding-bottom: 76.5%;
        }
        .prevNextCarrousel1 {
            //display: none;
        }
    }
}

.home {
    .hero {
        padding: 0 0 25rem;
    }
}