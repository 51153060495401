@charset "UTF-8";

// Desktop view [0 ; ∞]
// 01-utils
@import "01-default/01-utils/_configuration.scss";
@import "01-default/01-utils/_mixins.scss";
@import "01-default/01-utils/_framework.scss";
@import "01-default/01-utils/_reset.scss";
@import "01-default/01-utils/_base.scss";
@import "01-default/01-utils/_grillade.scss";
@import "01-default/01-utils/_styleguide.scss";
// 02-lib
@import "01-default/02-lib/_drop-down-menu.scss";
@import "01-default/02-lib/_access-tooltip.scss";
@import "01-default/02-lib/_tooltip-css.scss";
@import "01-default/02-lib/_fancybox.scss";
@import "01-default/02-lib/_datepicker.scss";
@import "01-default/02-lib/_jquery.timepicker.scss";
@import "01-default/02-lib/_scroll-pane.scss";
// 03-partials
@import "01-default/03-partials/_forms.scss";
@import "01-default/03-partials/_buttons.scss";
@import "01-default/03-partials/_links.scss";
@import "01-default/03-partials/_wrappers.scss";
@import "01-default/03-partials/_layouts.scss";
@import "01-default/03-partials/_text.scss";
@import "01-default/03-partials/_heading.scss";
@import "01-default/03-partials/_titles.scss";
@import "01-default/03-partials/_pagers.scss";
@import "01-default/03-partials/_filters.scss";
@import "01-default/03-partials/menus/_menu-skip.scss";
@import "01-default/03-partials/menus/_menu-stratis.scss";
@import "01-default/03-partials/menus/_menu-main.scss";
@import "01-default/03-partials/menus/_menu-cross.scss";
@import "01-default/03-partials/contents/_content.scss";
@import "01-default/03-partials/contents/_content__galeries.scss";
@import "01-default/03-partials/contents/_content__ddm.scss";
@import "01-default/03-partials/contents/_content__subpages.scss";
@import "01-default/03-partials/contents/_content__go-so-far.scss";
@import "01-default/03-partials/contents/_content__parallax.scss";
@import "01-default/03-partials/contents/_content__videos.scss";
@import "01-default/03-partials/contents/_content__typo3.scss";
@import "01-default/03-partials/lists/_lists.scss";
@import "01-default/03-partials/lists/_list-content.scss";
@import "01-default/03-partials/lists/_list-type-1.scss";
@import "01-default/03-partials/lists/_list-type-1--event.scss";
@import "01-default/03-partials/lists/_list-type-1--glossary.scss";
@import "01-default/03-partials/lists/_list-type-1--poll.scss";
@import "01-default/03-partials/lists/_list-type-2.scss";
@import "01-default/03-partials/lists/_list-type-2--publications.scss";
@import "01-default/03-partials/lists/_list-type-3.scss";
@import "01-default/03-partials/lists/_list-type-4.scss";
@import "01-default/03-partials/singles/_single.scss";
@import "01-default/03-partials/singles/_single--news.scss";
@import "01-default/03-partials/singles/_single--event.scss";
@import "01-default/03-partials/singles/_single--poll.scss";
@import "01-default/03-partials/objects/_breadcrumb.scss";
@import "01-default/03-partials/objects/_tools.scss";
@import "01-default/03-partials/objects/_tarteraucitron.scss";
@import "01-default/03-partials/objects/_logo.scss";
@import "01-default/03-partials/objects/_stratis.scss";
@import "01-default/03-partials/objects/_page-number.scss";
@import "01-default/03-partials/objects/_popin-homepage.scss";
@import "01-default/03-partials/objects/_print-banner.scss";
@import "01-default/03-partials/objects/_newsletter.scss";
@import "01-default/03-partials/objects/_search.scss";
@import "01-default/03-partials/objects/_go-to-top.scss";
@import "01-default/03-partials/objects/_sitemap.scss";
@import "01-default/03-partials/objects/_list-document.scss";
@import "01-default/03-partials/objects/_list-infos.scss";
@import "01-default/03-partials/objects/_comments.scss";
@import "01-default/03-partials/objects/_site-infos.scss";
@import "01-default/03-partials/objects/_bloc-news.scss";
@import "01-default/03-partials/objects/_bloc-event.scss";
@import "01-default/03-partials/objects/_bloc-publications.scss";
@import "01-default/03-partials/objects/_bloc-directory.scss";
@import "01-default/03-partials/objects/_bloc-contact.scss";
@import "01-default/03-partials/objects/_top-bar.scss";
@import "01-default/03-partials/objects/_social-networks.scss";
@import "01-default/03-partials/objects/_hero.scss";
@import "01-default/03-partials/objects/_lang-switch.scss";
@import "01-default/03-partials/objects/_footer-social.scss";
@import "01-default/03-partials/objects/_fast-access.scss";
@import "01-default/03-partials/objects/_section.scss";
@import "01-default/03-partials/objects/_news.scss";
@import "01-default/03-partials/objects/_news-unit.scss";
@import "01-default/03-partials/objects/_agenda.scss";
@import "01-default/03-partials/objects/_event-unit.scss";
@import "01-default/03-partials/objects/_kiosk.scss";
@import "01-default/03-partials/objects/_publication.scss";
@import "01-default/03-partials/objects/_images.scss";
@import "01-default/03-partials/objects/_smart-agglo.scss";
@import "01-default/03-partials/objects/_content-gallery.scss";
@import "01-default/03-partials/objects/_technologies-carousel.scss";
@import "01-default/03-partials/objects/_interactive-map.scss";
@import "01-default/03-partials/objects/_results.scss";
@import "01-default/03-partials/objects/_project-directives.scss";
@import "01-default/03-partials/objects/_social-block.scss";
@import "01-default/03-partials/objects/_social-list.scss";
@import "01-default/03-partials/objects/_partners-block.scss";
@import "01-default/03-partials/objects/_partners-carousel.scss";
@import "01-default/03-partials/objects/_project-grand.scss";
@import "01-default/03-partials/objects/_event-popup.scss";
@import "01-default/03-partials/objects/_location.scss";
@import "01-default/03-partials/objects/_contact-list.scss";
@import "01-default/03-partials/objects/_cartographie.scss";
// 04-pages
// 05-shame
@import "01-default/05-shame/_shame.scss";

// @name Tablet view [0 ; 960]

@media (max-width: #{$tablet}px) {

    // 01-utils
    @import "02-tablet/01-utils/_reset.scss";
    @import "02-tablet/01-utils/_base.scss";
    @import "02-tablet/01-utils/_styleguide.scss";

    // 02-lib
    @import "02-tablet/02-lib/_fancybox.scss";

    // 03-partials
    @import "02-tablet/03-partials/_forms.scss";
    @import "02-tablet/03-partials/_buttons.scss";
    @import "02-tablet/03-partials/_wrappers.scss";
    @import "02-tablet/03-partials/_heading.scss";
    @import "02-tablet/03-partials/_pagers.scss";
    @import "02-tablet/03-partials/_filters.scss";
    @import "02-tablet/03-partials/menus/_menu-skip.scss";
    @import "02-tablet/03-partials/menus/_menu-stratis.scss";
    @import "02-tablet/03-partials/menus/_menu-main.scss";
    @import "02-tablet/03-partials/menus/_mobile-menu.scss";
    @import "02-tablet/03-partials/menus/_menu-cross.scss";
    @import "02-tablet/03-partials/contents/_content.scss";
    @import "02-tablet/03-partials/contents/_content__ddm.scss";
    @import "02-tablet/03-partials/contents/_content__galeries.scss";
    @import "02-tablet/03-partials/contents/_content__go-so-far.scss";
    @import "02-tablet/03-partials/lists/_list-type-1.scss";
    @import "02-tablet/03-partials/lists/_list-type-1--event.scss";
    @import "02-tablet/03-partials/lists/_list-type-2.scss";
    @import "02-tablet/03-partials/lists/_list-type-3.scss";
    @import "02-tablet/03-partials/objects/_search.scss";
    @import "02-tablet/03-partials/objects/_list-document.scss";
    @import "02-tablet/03-partials/objects/_list-infos.scss";
    @import "02-tablet/03-partials/objects/_comments.scss";
    @import "02-tablet/03-partials/objects/_bloc-publications.scss";
    @import "02-tablet/03-partials/objects/_hero.scss";
    @import "02-tablet/03-partials/objects/_top-bar.scss";
    @import "02-tablet/03-partials/objects/_social-networks.scss";
    @import "02-tablet/03-partials/objects/_logo.scss";
    @import "02-tablet/03-partials/objects/_bloc-contact.scss";
    @import "02-tablet/03-partials/objects/_bloc-news.scss";
    @import "02-tablet/03-partials/objects/_bloc-event.scss";
    @import "02-tablet/03-partials/objects/_site-infos.scss";
    @import "02-tablet/03-partials/objects/_footer-social.scss";
    @import "02-tablet/03-partials/objects/_results.scss";
    @import "02-tablet/03-partials/objects/_fast-access.scss";
    @import "02-tablet/03-partials/objects/_section.scss";
    @import "02-tablet/03-partials/objects/_news.scss";
    @import "02-tablet/03-partials/objects/_event-unit.scss";
    @import "02-tablet/03-partials/objects/_agenda.scss";
    @import "02-tablet/03-partials/objects/_kiosk.scss";
    @import "02-tablet/03-partials/objects/_publication.scss";
    @import "02-tablet/03-partials/objects/_images.scss";
    @import "02-tablet/03-partials/objects/_content-gallery.scss";
    @import "02-tablet/03-partials/objects/_project-directives.scss";
    @import "02-tablet/03-partials/objects/_project-grand.scss";
    @import "02-tablet/03-partials/objects/_partners-block.scss";
    @import "02-tablet/03-partials/objects/_partners-carousel.scss";
    @import "02-tablet/03-partials/objects/_social-block.scss";
    @import "02-tablet/03-partials/objects/_social-list.scss";
    @import "02-tablet/03-partials/objects/_technologies.scss";
    @import "02-tablet/03-partials/objects/_interactive-map.scss";
    @import "02-tablet/03-partials/objects/_location.scss";
    @import "02-tablet/03-partials/_layout.scss";
    @import "02-tablet/03-partials/_text.scss";
    @import "02-tablet/03-partials/_titles.scss";

    // 04-pages

    // 05-shame
    @import "02-tablet/05-shame/_shame.scss";
}

// @name Smartphone view [0 ; 768]

@media (max-width: #{$smartphone}px) {

    // 01-utils

    // 02-lib

    // 03-partials
    @import "03-smartphone/03-partials/_wrappers.scss";
    @import "03-smartphone/03-partials/_layouts.scss";
    @import "03-smartphone/03-partials/_text.scss";
    @import "03-smartphone/03-partials/_heading.scss";
    @import "03-smartphone/03-partials/_titles.scss";
    @import "03-smartphone/03-partials/contents/_content.scss";
    @import "03-smartphone/03-partials/contents/_content__galeries.scss";
    @import "03-smartphone/03-partials/contents/_content__subpages.scss";
    @import "03-smartphone/03-partials/contents/_content__go-so-far.scss";
    @import "03-smartphone/03-partials/contents/_content__parallax.scss";
    @import "03-smartphone/03-partials/contents/_content__typo3.scss";
    @import "03-smartphone/03-partials/contents/_content__ddm.scss";
    @import "03-smartphone/03-partials/lists/_list-type-1.scss";
    @import "03-smartphone/03-partials/lists/_list-type-1--event.scss";
    @import "03-smartphone/03-partials/lists/_list-type-2.scss";
    @import "03-smartphone/03-partials/lists/_list-type-3.scss";
    @import "03-smartphone/03-partials/singles/_single--event.scss";
    @import "03-smartphone/03-partials/singles/_single--poll.scss";
    @import "03-smartphone/03-partials/objects/_logo.scss";
    @import "03-smartphone/03-partials/objects/_sitemap.scss";
    @import "03-smartphone/03-partials/objects/_top-bar.scss";
    @import "03-smartphone/03-partials/objects/_hero.scss";
    @import "03-smartphone/03-partials/objects/_lang-switch.scss";
    @import "03-smartphone/03-partials/objects/_bloc-publications.scss";
    @import "03-smartphone/03-partials/objects/_site-infos.scss";
    @import "03-smartphone/03-partials/objects/_footer-social.scss";
    @import "03-smartphone/03-partials/objects/_menu-cross.scss";
    @import "03-smartphone/03-partials/objects/_stratis.scss";
    @import "03-smartphone/03-partials/objects/_bloc-news.scss";
    @import "03-smartphone/03-partials/objects/_bloc-event.scss";
    @import "03-smartphone/03-partials/objects/_bloc-contact.scss";
    @import "03-smartphone/03-partials/objects/_results.scss";
    @import "03-smartphone/03-partials/objects/_fast-access.scss";
    @import "03-smartphone/03-partials/objects/_news.scss";
    @import "03-smartphone/03-partials/objects/_section.scss";
    @import "03-smartphone/03-partials/objects/_agenda.scss";
    @import "03-smartphone/03-partials/objects/_event-unit.scss";
    @import "03-smartphone/03-partials/objects/_publication.scss";
    @import "03-smartphone/03-partials/objects/_kiosk.scss";
    @import "03-smartphone/03-partials/_filters.scss";
    @import "03-smartphone/03-partials/_pagers.scss";
    @import "03-smartphone/03-partials/objects/_newsletter.scss";
    @import "03-smartphone/03-partials/objects/_content-gallery.scss";
    @import "03-smartphone/03-partials/objects/_project-directives.scss";
    @import "03-smartphone/03-partials/objects/_project-grand.scss";
    @import "03-smartphone/03-partials/objects/_partners-block.scss";
    @import "03-smartphone/03-partials/objects/_partners-carousel.scss";
    @import "03-smartphone/03-partials/objects/_social-block.scss";
    @import "03-smartphone/03-partials/objects/_social-list.scss";
    @import "03-smartphone/03-partials/objects/_technologies.scss";
    @import "03-smartphone/03-partials/objects/_interactive-map.scss";
    @import "03-smartphone/03-partials/objects/_images.scss";
    @import "03-smartphone/03-partials/objects/_list-document.scss";
    @import "03-smartphone/03-partials/objects/_comments.scss";
    @import "03-smartphone/03-partials/objects/_event-popup.scss";
    @import "03-smartphone/03-partials/objects/_location.scss";
    @import "03-smartphone/03-partials/objects/_cartographie.scss";
    @import "03-smartphone/03-partials/objects/_technologies-carousel.scss";

    // 04-pages

    // 05-shame
    @import "03-smartphone/05-shame/_shame.scss";
}