.go-so-far {
    padding: 4.8em 0 3.9em;
    margin: 0 -2rem;
    &__wrapper {
        flex-wrap: wrap;
        justify-content: center;
        > :last-child {
            width: 55.1%;
        }
    }
    &__item {
        flex: none !important;
        width: 50%;
        padding-bottom: 29px;
    }
}