.news {
    &__list {
        margin: 0 0 0.9rem;
        padding: 0;
        &-item {
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
        }
    }
}