// @name cookies banner
/* stylelint-disable */
#tarteaucitronRoot {
    .tarteaucitronH1 {
        font-size: 2.5rem;
    }

    div#tarteaucitronInfo {
        font-size: 1.6rem !important;

        a {
            font-size: 1.6rem;
        }
    }

    #tarteaucitronPersonalize {
        background: $color-white;
        border: solid 1px rgba($color-3--2, 0.5);
        color: $color-black;

        &:hover, &:focus {
            background: transparent;
            color: $color-white;
        }
    }

    #tarteaucitronAlertBig {

        #tarteaucitronCloseAlert {
            background: transparent;
            color: $color-white;
        }

        > button {
            @media (max-width: 768px) {
                margin: 0.5rem;
            }
        }
    }

    #tarteaucitron #tarteaucitronServices {

        .tarteaucitronDetails {
            background: $color-black;
            display: block !important;
            max-width: inherit;
            padding: 10px 20px;
            position: static;
        }

        .tarteaucitronTitle {
            background: $color-black;

            button {
                margin-top: 0;
            }
        }
    }
}

#tarteaucitronDisclaimerAlert {
    display: block !important;
    margin: 0 0 1rem !important;
    text-align: center !important;
}

#tarteaucitronRoot #tarteaucitronAlertBig {
    box-sizing: border-box !important;
    padding: 2rem 3rem !important;
}

div#tarteaucitronInfo {
    position: inherit !important;
}

#tarteaucitronAlertSmall {
    left: 0;
    right: auto !important;
}

#fancyboxLine {
    display: none;
}

/* stylelint-enable */
