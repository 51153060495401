.kiosk {
    background: $color-white url(#{$image-bg-url}main.png);

    .section__header {
        svg {
            stroke-width: 3px;
        }
    }

    .publication_sm {
        &:not(:last-child) {
            margin-bottom: 2.7rem;
        }
    }

    &__content {
        @extend .clear-fix;

        margin: -0.5rem 0 1.8rem;
    }

    &__col {
        float: left;
        min-height: 1px;
        width: (320 / 1220 * 100%);

        &:not(:last-child) {
            padding-right: 2rem;
        }

        &_lg {
            width: (580 / 1220 * 100%);
        }
    }
}