.news {
    &__list {
        @extend .clear-fix;

        margin: 0 -1.4rem 3.5rem;
    }

    &__list-item {
        float: left;
        min-height: 1px;
        padding: 0 1.4rem;
        width: 25%;
    }
}