// @name Document list
// @description List of document in publications list

.list-document__listitems {
    width: 100%;
    font-size: 0;
    line-height: 0;
    margin: 0 -1%;
    > * {
        width: 33.33%;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px 40px;
        font-size: 1.4rem;
        line-height: 1.2;
    }
    .button-1, .button-2 {
        padding-left: 2.2em;
        padding-right: 1em;
        &:before {
            left: em(1.5, 1.4);
        }
    }
}

.list-document__item {
    &.list-document__item--postulate {
        text-align: right;
        align-self: flex-end;
    }
}

.list-document__title-file {
    font-size: 1.4rem;
    font-weight: $bold;
    padding-left: em(4, 1.4);
    padding-bottom: 9px;
    margin-bottom: em(1.5, 1.4);
    overflow: hidden;
    word-break: break-all;
    .list-document__file-size {
        display: block;
        font-weight: $light;
    }
    @include default-icons-absolute-before('\e005', 35px, $color-2--2, 2px, inherit, inherit, 0, 'icons-project');
    &.list-document__title-file--pdf {
        &:before {
            content: "\e003";
        }
    }
    &.list-document__title-file--doc {
        &:before {
            content: "\e001";
        }
    }
    &.list-document__title-file--jpg {
        &:before {
            content: "\e002";
        }
    }
    &.list-document__title-file--xls {
        &:before {
            content: "\e006";
        }
    }
    &.list-document__title-file--ppt {
        &:before {
            content: "\e004";
        }
    }
    &.list-document__title-file--zip {
        &:before {
            content: "\e007";
        }
    }
}

.list-document__download {
    &:before {
        content: "\e063";
        font-size: 10px;
    }
}

.list-document__read {
    &:before {
        content: "\e06e";
        font-size: 10px;
    }
}

// @name list document type 1
// @description Liste in bloc
.list-document-1__listitems {

}

.list-document-1__item {
    margin: 3px 0;
    .button-2 {
        font-weight: 400;
        font-size: 1.4rem;
        &_inverse {
            font-size: 1.3rem;
            .ico {
                background: #000;
                svg {
                    fill: #fff;
                }
            }
            &:focus,
            &:hover {
                .ico {
                    background: #fff;
                    svg {
                        fill: #000;
                    }
                }
            }
        }
    }
    .encadre {
        &:after {
            font-family: icons-default;
            content: '\e06d';
            font-size: 10px;
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

