/*
 * CSS Styles that are needed by jScrollPane for it to operate correctly.
 *
 * Include this stylesheet in your site or copy and paste the styles below into your stylesheet - jScrollPane
 * may not operate correctly without them.
 */

.jspContainer {
    overflow: hidden;
    position: relative;
}

.jspPane {
    position: absolute;
}

.jspVerticalBar {
    position: absolute;
    top: 0;
    right: 1px;
    width: 10px;
    height: 100%;
}

.jspHorizontalBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
    background: red;
}

.jspCap {
    display: none;
}

.jspHorizontalBar .jspCap {
    float: left;
}

.jspTrack {
    position: relative;
}

.jspDrag {
    background: #000;
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
}

.jspHorizontalBar .jspTrack,
.jspHorizontalBar .jspDrag {
    float: left;
    height: 100%;
}

.jspArrow {
    text-indent: -20000px;
    display: block;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: none;
    position: relative;
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        border-bottom: 3px solid #000;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: '';
    }
    &Down {
        &:after {
            border-bottom-width: 0;
            border-top: 3px solid #000;
        }
    }
}

.jspArrow.jspDisabled {
    cursor: default;
}

.jspVerticalBar .jspArrow {
    height: 12px;
}

.jspHorizontalBar .jspArrow {
    width: 10px;
    float: left;
    height: 100%;
}

.jspVerticalBar .jspArrow:focus {
    outline: none;
}

.jspCorner {
    background: #eeeef4;
    float: left;
    height: 100%;
}

/* Yuk! CSS Hack for IE6 3 pixel bug :( */
* html .jspCorner {
    margin: 0 -3px 0 0;
}