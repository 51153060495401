.results {
    border: 30px solid #fcd808;
    background: #f2f2ef;
    padding: 50px 0 68px;
    .title {
        font-size: 4.1rem;
        color: #111212;
        font-weight: $light;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 55px;
    }
    .line {
        margin: 27px 0 0;
        display: block;
        width: 12.6rem;
        height: .6rem;
        background: url(../Images/bg/sep.png) 0 100% repeat-x;
    }
    &-inner {
        display: flex;
    }
    &-block {
        width: 50%;
        display: flex;
        color: #111212;
        &:not(:last-child) {
            border-right: 1px solid #111212;
        }
        &-info {
            width: 52%;
            font-size: 1.8rem;
            line-height: 1.2;
            color: #111212;
            &:first-child {
                width: 46%;
                padding: 0 2% 0 0;
            }
        }
        &-chart {
            padding: 0 1% 0 4.2%;
            font-size: 1.4rem;
            &-title {
                display: block;
                font-size: 1.6rem;
                margin: 0 0 4px;
                text-transform: uppercase;
            }
            > div {
                width: 100%;
            }
        }
    }
    &-form {
        margin-bottom: 0;
    }
    .radio {
        margin: 0 0 34px;
        > div {
            margin: .4em 0;
            &:first-child {
                margin-top: 0;
            }
        }
        label {
            font-size: 1.6rem;
            color: #000;
            line-height: 1.3em;
            padding: 0 0 0 30px;
            &:before {
                width: 21px;
                height: 21px;
                border: 2px solid #d3d3d3;
            }
        }
        input[type="radio"]:checked + label:after {
            width: 9px;
            height: 9px;
            top: 6px;
            left: 6px;
        }
    }
    button[type="submit"] {
        font-size: 1.6rem;
        .check {
            transition: all ease .3s;
            color: #fff;
            &:before {
                left: 0 !important;
            }
        }
        .ico {
            transition: all ease .3s;
        }
        .plus {
            &:before,
            &:after {
                transition: all ease .3s;
            }
        }
        &:hover,
        &:focus {
            background: #555;
            color: #fff;
            .ico {
                background: #fcd808;
                .plus {
                    background: #fff;
                    &:before,
                    &:after {
                        background: #000;
                    }
                }
                .check {
                    &:before {
                        color: #000;
                    }
                }
            }
        }
    }
    .answerChart {
        margin: 11px 0 0 0;
        #pieChartTarget {
            width: 210px;
            height: 210px;
            float: left;
            margin: 0 3em 0 -12px;
        }
        .list-pie-chart {
            overflow: hidden;
            margin: 0;
            padding: 23px 0 0;
            li {
                color: #111212;
                font-size: 1.3rem;
                font-weight: $light;
                padding: 0;
                margin: 0 0 5px;
                &:before {
                    display: none;
                }
                strong {
                    font-size: 1.9rem;
                    font-family: $typo-2;
                    display: inline-block;
                    vertical-align: bottom;
                    margin: 0 3px 0 0;
                    font-weight: $normal;
                }
            }
        }
    }
}

$pie_colors-home: #fcd808, #555555, #dbbb00, #d3d3d3, #ffee8a, #ffffff, #b4d75e, #57ba81, #97dab3, #50c3bc, #a0afb7, #306a96, #2d4c59, #3da4c6, #cfaee3, #9873ae;
@for $i from 1 through length($pie_colors-home) {
    $j: $i - 1; // Because $i doesn't begin by 0
    .results #pieChartTarget .pie#{$j} {
        fill: nth($pie_colors-home, $i);
    }
    .results > .pie#{$j} {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            display: block;
            background: nth($pie_colors, $i);
        }
    }
}