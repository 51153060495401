.section {
    padding: 5.5rem 0 5.1rem;
    &__header {
        padding-left: 0;
        text-align: left;
    }
}

.section__title,
.content .section__title {
    padding-left: 0;
    font-size: 2.4rem;
    margin: 0;
    &:before {
        display: none;
    }
    .txt {
        max-width: 20rem;
        text-align: left;
        margin: 10px 0 0;
    }
    &:after {
        top: 1.9rem;
        transform: none;
    }
}

.section__title .ico svg,
.content .section__title .ico svg {
    height: 19px;
}

.section__title .ico,
.content .section__title .ico {
    width: 4.4rem;
    height: 4.4rem;
}

.section__title span,
.content .section__title span {
    vertical-align: top;
}