// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }
    .bloc-news__title {
        $bloc-news__title__font-size: 1.6;
        font-family: $typo-3;
        font-weight: $light;
        font-size: 1.5rem;
        line-height: (16 / 15);
        margin: 0.4rem 0 3rem;
        color: $color-1--1;
        a {
            color: $color-black;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }
    .link-view__feature {
        a {
            &:hover,
            &:focus {
                .ico {
                    background: #fff;

                }
            }
        }
    }
}