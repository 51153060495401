.content {
    h2,
    .h2 {
        font-size: 2.6rem;
        line-height: 1.1;
    }
    h3,
    .h3 {
        font-size: 1.8rem;
        line-height: 1.1;
        margin: 2em 0 0.88em 0;
    }
    h4,
    .h4 {
        line-height: 1.25;
        margin: 3.7rem 0 1.7rem;
    }
    h5,
    .h5 {
        margin: 2.5rem 0 1.6rem 0;
    }
    p {
        font-size: 1.5rem;
    }
    .table-wrapper {
        margin: 5.1rem 0 6.9rem;
    }
    .table-wrapper-inner {
        margin: 0 -10px 0 0;
        width: auto;
    }
    table {
        width: 767px;
    }
    .encadre,
    .encadre--attention,
    .encadre--information,
    .encadre--download  {
        margin: 4.96rem 0 2.3rem;
        padding: 10.2rem 2.5rem 4.1rem;
        line-height: 1.33;
        &:before,
        &:after {
            top: 20px;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
        &:after {
            top: 37px;
        }
    }
    .videos {
        margin: 4.7rem 0 5.4rem;
    }
    blockquote {
        margin: 3.3rem 0;
        p {
            font-size: 1.6rem;
        }
    }
    .column--3,
    .column--2 {
        columns: 1;
        margin: 1.9rem 0 2.2rem;
    }
}

.locate {
    margin: 4.8rem 0 4.6rem;
    .title {
        font-size: 2.4rem;
        padding: 0 0 0 5.4rem;
        .ico {
            width: 4.4rem;
            height: 4.4rem;
            svg {
                width: 19px;
                height: 20px;
            }
        }
    }
    .map-holder {
        margin: 0 -10px;
        img {
            display: none;
        }
    }
    .adr {
        margin: 0.8rem 0 0.9rem;
        line-height: 1.2;
        span {
            display: inline;
        }
    }
    .sidebar-info {
        padding: 2.9rem 3.9rem 3.6rem;
        position: static;
        width: 100%;
        .button-2 {
            .ico__arrow {
                &:before {
                    content: '\e027' !important;
                    font-size: 1.4rem !important;
                }
            }
            &-loc {
                display: inline-block;
                vertical-align: top;
                padding: 0.7rem 1.7rem 0.5rem 1.7rem;
            }
        }
        .link-holder {
            margin: 0 0 18px;
        }
    }
    .btns {
        position: static;
        width: 140px;
    }
}

.downloads {
    .title {
        font-size: 2.4rem;
        padding: 0 0 0 5.4rem;
        .ico {
            width: 4.4rem;
            height: 4.4rem;
            svg {
                width: 19px;
                height: 18px;
            }
        }
    }
    .downloads-block {
        padding: 31px 13px 25px;
    }
    .downloads-list {
        &:before {
            display: none;
        }
        li {
            width: 100% !important;
            display: flex;
            align-items: center;
            min-height: 39px;
            margin: 0 0 2.9rem !important;
            position: relative;
            &:last-child {
                margin: 0 !important;
            }
            a {
                position: static;
                svg {
                    top: 0;
                }
            }
        }
    }
}
