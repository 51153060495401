// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    display: none;
    overflow: hidden;
    .bloc-news__picture {
        float: none;
        width: 100%;
        display: block;
        margin: 0;
    }
    .bloc-news__wrapper {
        padding-top: 20px;
    }
    .bloc-news__title {
        font-size: 1.5rem;
        line-height: 1.1;
        margin: 0.4rem 0 3rem;
    }
}