.event-unit {
    display: flex;
    align-items: center;
    .category {
        font-size: 1.2rem;
    }
    &__img {
        width: 300px;
        margin: 0 10px 0 0;
        background: none;
        height: auto;
        padding: 0;
        img {
            height: auto;
            position: static;
        }
    }
    &__wrap {
        display: flex;
        align-items: center;
        width: calc(100% - 310px);
        .event-unit__content {
            width: calc(100% - 155px);
        }
        .event-unit__date {
            margin: 3.5rem 2.3rem 0 -4.5rem;
        }
    }
}

.event-unit__date,
.content .event-unit__date {
    margin: 0 1rem 0 -4.5rem;
}

.event-unit__title, .content .event-unit__title {
    font-size: 1.8rem;
}