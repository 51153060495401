// max width for content element
$content__max-width: ($smartphone/10) - $column-bloc__width - $column-bloc__padding-left;

.content {
    // @name Table
    h2,
    .h2 {
        font-size: 3rem;
    }
    h3,
    .h3 {
        font-size: 2.3rem;
    }
    p {
        font-size: 1.4rem;
    }
    .table-wrapper {
        margin: 8.2rem 0 6rem;
    }
    .encadre,
    .encadre--attention,
    .encadre--information,
    .encadre--download {
        margin: 2.46rem 0 2.9rem;
        padding: 2.8rem 7rem 3.3rem 10rem;
        font-size: 1.5rem;
    }
    .videos {
        margin: 4.8rem 1rem 8.7rem;
    }
    ul,
    ol {
        font-size: 1.4rem;
        > li {
            margin: 0 0 0.4rem;
        }
    }
    blockquote {
        margin: 3.4rem 0 3.8rem;
        p {
            font-size: 1.8rem;
            line-height: 1.2;
        }
        .ico {
            top: 0.6rem;
        }
    }
}

.locate .btns .button-2 {
    font-size: 1.4rem;
}

.downloads {
    .downloads-list {
        li {
            margin: 0 0 3.6rem;
        }
    }
    .downloads-block {
        padding: 4.7% 2.7% 4.9%;
    }
}
