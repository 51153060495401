.social-block {
    padding-top: 63px;
    padding-bottom: 58px;
    .section__header {
        padding: 0;
        .section__title {
            &:after {
                display: none;
            }
            .txt {
                max-width: none;
            }
        }
        &__social {
            margin: 10px 0 -10px;
        }
    }
}