// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    overflow: hidden;
    .bloc-event__picture {
        float: left;
        width: 300px;
        margin: 0 141px 50px 0;
        img {
            margin: 0;
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .bloc-event__item {
        position: relative;
    }
    .bloc-event__wrapper-date {
        position: absolute;
        top: 122px;
        left: 257px;
    }
    .bloc-event__wrapper {
        padding-top: 53px;
    }
    .link-view {
        margin-top: 3.6rem;
    }
}