.filters {
    form {
        margin: 2em 0 0;
    }
}

.filters__wrapper {
    display: block;
    table-layout: inherit;
}

.filters__fields {
    .filters__fields-wrapper {
        display: block;
        flex-wrap: inherit;
        justify-content: inherit;
        margin: 0;
        > .filters__field-wrapper {
            align-self: inherit;
            flex: inherit;
            margin-right: 0;
            margin-left: 0;
        }
    }
    &.filters__fields--column-2, &.filters__fields--column-3, &.filters__fields--column-4 {
        .filters__fields-wrapper {
            > .filters__field-wrapper {
                width: 100%;
            }
        }
    }
}

.filters__fields, .filters__button-wrapper {
    display: block;
    vertical-align: inherit;
}

.filters__button-wrapper {
    padding-left: 0;
    padding-top: 0;
    text-align: right;
    width: 100%;
    text-align: center;
}
