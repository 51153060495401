.footer-social {
    float: none;
    width: auto;
    margin: 0 0 30px;

    &-list {
        margin: 23px -16px 0;
        justify-content: center;

        li {
            width: 54px;
            margin: 0 2px;
        }
    }
}