.partners-carousel,
.content .partners-carousel {
    padding: 0 44px;
    position: relative;
    .item {
        padding: 0 12px;
        text-align: center;
    }
    &-img {
        height: 77px;
        position: relative;
        margin: 0 0 36px;
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            max-width: 100%;
        }
    }
    &-title {
        display: block;
        background: #111212;
        padding: 8px 10px;
        color: #fff;
        font-size: 1.1rem;
        line-height: 1.2;
        font-weight: 300;
        font-family: $typo-1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover,
        &:focus {
            background: #fff;
            color: #000;
        }
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        top: 30px;
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        &:before {
            display: none;
        }
        button {
            background: none;
            border: none;
            width: 100%;
            height: 100%;
            position: relative;
            overflow: hidden;
            &:after {
                font-family: icons-default;
                font-size: 16px;
                line-height: 1;
                color: #282d33;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            img {
                display: none;
            }
        }
    }
    .prevCaroussel3 {
        left: -13px;
        button {
            &:after {
                content: '\e01a';
            }
        }
    }
    .nextCaroussel3 {
        right: -13px;
        button {
            &:after {
                content: '\e01b';
            }
        }
    }
    .cycle-carousel-wrap {
        .item {
            opacity: 1 !important;
            visibility: visible !important;
        }
    }
}