.project-grand,
.content .project-grand {
    flex-wrap: wrap;
    margin: 0 0 62px;
    &-img {
        flex: none;
        width: 100%;
        min-height: 0;
        margin: 0 0 20px;
        img {
            position: static;
            transform: translate(0, 0);
            min-width: 0;
            min-height: 0;
            width: 100%;
            display: block;
        }
        &:after {
            position: absolute;
            bottom: -135px;
            right: -88px;
            width: 451px;
            height: 451px;
            background: url(../Images/pictos/decore-circle-5.png) no-repeat;
            content: '';
        }
    }
    &-description {
        flex: none;
        width: 100%;
        margin: 0;
        text-align: center;
        padding: 40px 34px 52px;
        h3 {
            font-size: 2rem;
        }
        p {
            font-size: 1.6rem;
        }
        .title-4 {
            margin-top: -56px;
            margin-bottom: 22px;
        }
        &:after {
            display: none;
        }
    }
}