.news-unit,
.content .news-unit {
    position: relative;
    overflow: hidden;
    padding-bottom: (285 / 284 * 100%);
    height: 0;
    background: none;
    display: block;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.3s linear;
        content: '';
    }
    &:hover,
    &:focus {
        .news-unit__txt {
            text-decoration: underline;
        }
        &:after {
            opacity: 1;
        }
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $color-3--3;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: (162 / 285 * 100%);
            background: linear-gradient(to bottom, rgba(17, 18, 18, 0) 0%, rgba(17, 18, 18, 0.04) 16%, rgba(17, 18, 18, 0.15) 29%, rgba(17, 18, 18, 0.35) 43%, rgba(17, 18, 18, 0.88) 71%, rgba(17, 18, 18, 1) 80%, rgba(17, 18, 18, 1) 100%);
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 2rem 3rem;
        z-index: 2;
    }

    &__txt,
    .content &__txt {
        min-height: 5rem;
        margin: 0;
        font-weight: $light;
        font-size: 1.5rem;
        line-height: (16 / 15);
        color: #fff;
    }

    &__category {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: $normal;
        font-size: 1.2rem;
        line-height: 1;
        font-family: $typo-2;
        margin: 0 0 0.5rem -3rem;
        padding: .2rem 1rem .2rem 3rem;
        background: $color-2--1;
    }

    &__play {
        position: absolute;
        left: 50%;
        top: 35.7%;
        transform: translate(-50%, -50%);
    }
}