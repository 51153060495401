// @name Heading single news
// @dependency .heading
.heading--single-news {
    .heading__picture {
        min-width: 30%;
        img {
            width: auto;
            max-width: 100%;
        }
    }
}

.heading {
    padding: 4rem 0;
    .heading__wrapper {
        padding: 0 1rem;
    }
    h1 {
        font-size: 4rem;
        line-height: 1.2;
    }
    .subtitle + h1 {
        margin-top: 1.1rem;
    }
}