// @name Header page
.header {
    @extend .clear-fix;
    padding-bottom: 1.6rem;

    &__nav-bar {
        @extend .wrapper-1220;

        position: relative;
        min-height: 9rem;
        padding-top: 1px;

        .lang-switch {
            display: none;
        }

        &:before,
        &:after {
            content: '';
            visibility: visible;
            position: absolute;
            display: block;
            top: 50%;
            transform: translateY(-50%);
            width: 50vw;
            height: .6rem;
            background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
            margin-top: -0.4rem;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }
    &-no-image {
        .hero__img {
            height: 166px;
            background: none !important;
        }
        .top-bar {
            z-index: 102;
            background: #585959;
        }
    }
}

.page {
    overflow: hidden;
    position: relative;
}

// @name Top of content
.top-of-content {
}

.top-of-content__wrapper {
    @extend .wrapper-1220;
    padding-top: 2em;
    padding-bottom: 2em;
}

// @name Main wrapper
.main {
    display: block;
    width: 100%;
    clear: both;
    background: $color-white url(#{$image-bg-url}main.png);

    .home & {
        background: none;
    }
}

// @name Main section
.section-main {

}

.section {
    padding: 2rem 0;

    &__title {
        text-align: center;
        font-weight: $bold;
        font-size: 3.4rem;
    }
}

// @name Main section wrappers
.section-main__wrapper {
    @extend .wrapper-1160;

    padding-top: 1.8rem;
    padding-bottom: 3.4em;
    background: #fff;
    margin-bottom: 52px;

    .home & {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.section-main__wrapper-2 {
    display: flex;
    width: 100%;
}

// @name Main section content
.section-main__content {
    width: 100%;
}

// @name Main section aside
.section-main__aside {
    padding: 1.4rem 0 0;
    > .column-bloc {
        *:first-child {
            margin-top: 0;
        }
    }
}

// @name Footer
.footer {
    background: #111212;
    position: relative;
    z-index: 1;
    padding: 32px 0 33px;
    &:after {
        position: absolute;
        top: -36px;
        left: 50%;
        width: 9999px;
        height: 100px;
        z-index: -1;
        background: #111212;
        -webkit-transform: translate(-50%, 0) rotate(-2deg);
        -ms-transform: translate(-50%, 0) rotate(-2deg);
        transform: translate(-50%, 0) rotate(-2deg);
        content: '';
    }
    a {
        &:focus {
            outline-color: #fff;
        }
    }
}

@media (min-width: 768px) {
    .mob-visible {
        display: none !important;
    }
}