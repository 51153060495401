.column-bloc.bloc-contact {
    .bloc-contact__inner {
        padding: 0 2rem 4rem;
    }

    .bloc-contact-col-address,
    .bloc-contact-col-btn {
        width: auto;
    }

    .bloc-contact-col-address {
        margin: 0 0 1.5rem;

        p {
            line-height: 1.5;
            margin-bottom: 1.4rem;
        }
    }

    .bloc-contact__wrapper {
        display: block;
    }

    .phone {
        font-size: 1.9rem;
    }

    .bloc-contact-col-btn {
        text-align: center;

        .button-2 {
            display: inline-block;
            vertical-align: top;
        }

        p {
            padding-top: 0.7rem;
        }
    }
}