.agenda {
    background: $color-3--1;

    .rss {
        border-color: $color-3--2;
    }

    &__list {
        @extend .clear-fix;

        margin: 0 -2rem 4.8rem;
    }

    &__list-item {
        float: left;
        min-height: 1px;
        padding: 0 2rem;
        width: (1 / 3 * 100%);
    }
}