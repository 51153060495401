.images {
    &__pic {
        height: auto;
        padding: 0;
        position: relative;
        img {
            position: static;
        }
    }
    .button-2 {
        display: none;
    }
    &__play {
        display: block !important;
    }
    .listItems {
    }
    &__main {
        margin: 0 0.3rem 0rem;
        padding: 0 3rem;
        position: relative;
    }
    &__content {
        opacity: 1 !important;
        position: static;
        background: transparent !important;
        &-inner {
            position: static;
            transform: none;
            padding: 2rem !important;
        }
        p {
            color: #ffffff;
            font-size: 1.5rem;
            white-space: normal;
        }
    }
    .prevNextCaroussel3 {
        margin: 0;
        padding: 0;
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        top: 26%;
        padding: 0;
        margin: 0;
        &:before {
            display: none;
        }
        img {
            display: none;
        }
        button {
            @include default-icons-before('', 0, 2.4rem, #fcd808);
        }
    }
    .prevCaroussel3 {
        left: 0;
        button {
            &:before {
                content: '\e010';
            }
        }
    }
    .nextCaroussel3 {
        right: 0;
        button {
            &:before {
                content: '\e011';
            }
        }
    }
}

.images__item,
.content .images__item {
    background: transparent !important;
    padding: 0 2rem;
    float: none !important;
    overflow: visible;
    position: relative !important;
    vertical-align: top;
}

.images__item_sm,
.content .images__item_sm {
    float: none;
}

.images__title,
.content .images__title {
    font-size: 1.9rem;
    color: #fcd808;
    background: url(../Images/bg/sep-3.png) 0 100% repeat-x;
    padding: 0 1.1rem 2rem;
}
