.images {
    padding-bottom: 2rem !important;
    &__main {
        padding: 0;
        margin: 0;
    }
    &__content {
        &-inner {
            padding: 3rem 2rem !important;
        }
    }
    .prevCaroussel3,
    .nextCaroussel3 {
        z-index: 9999;
    }
}

.images__item, .content .images__item {
    padding: 0;
}