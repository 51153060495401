.bloc-publications {
    &__item {
        overflow: hidden;
    }
    &__wrapper {
        overflow: visible;
        margin-top: 20px;
    }
    &__title {
        font-size: 2.1rem;
    }
    &__picture {
        margin-bottom: 17px;
    }
}