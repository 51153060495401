// @name Liste type 2
// @description Basic list by default 4 columns
// @state .list-type-2--3col - 3 columns
// @state .list-type-2--2col - 2 columns
.list-type-2 {
    &.list-type-2--3col {
        .list-type-2__listitems {
            .list-paginated__wrapper {
                > * {
                    flex-basis: 100%;
                }
            }
        }
    }
    &.list-type-2--2col {
        .list-type-2__listitems {
            .list-paginated__wrapper {
                > * {
                    flex-basis: 100%;
                }
            }
        }
    }
    .list-type-2__picture {
        img {
            width: 100%;
        }
    }
}

.list-type-2__listitems {
    .list-paginated__wrapper {
        > * {
            flex-basis: 100%;
        }
    }
}

.list-type-2__picture--2 {
    height: auto;
}