@media (min-width: 1221px) {
.partners-block,
.content .partners-block {
    .listItems .item-no-js{
    	position: static; top: 0px; left: 0px; z-index: 95; display: inline-block; width: 19%;
    }
}
}
.partners-block,
.content .partners-block {
	padding-top: 50px;
	padding-bottom: 106px;
	background: #f2f2ef;
	.title-4 {
		margin: -67px 0 62px;
	}
    .link-block-wrap {
        > a,a.visited{
            background: #111212 !important;
            text-align: center !important;
            color: #fff !important;
            &:hover,&:active{
                background: #fcd808 !important;
                color: #111212 !important;
            }
            &:after{
                content:''!important;
                position:absolute !important;
            }
        }
    }
}