.publication {
    @extend .clear-fix;

    position: relative;

    &__img,
    .content &__img {
        float: left;
        width: 28.1rem;
        background: url(#{$image-bg-url}publication-frame.png) 0 0 no-repeat;
        background-size: 100% auto;
        margin: -2.6rem 1.6rem 0 -1.8rem;

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__img-inner {
        position: relative;
        height: 0;
        padding-bottom: (354 / 281 * 100%);
    }

    &__img-frame {
        overflow: hidden;
        position: absolute;
        left: 7.2%;
        top: 4.3%;
        width: (240 / 281 * 100%);
        height: (324 / 354 * 100%);
    }

    &__content {
        overflow: hidden;
    }

    &__head {
        color: $color-black;
        margin: 0 0 1.8rem;
        a {
            background: none;
            color: inherit;
            display: block;
            padding: 0;
            &:hover,
            &:focus {
                text-decoration: underline;
                .publication__num, .publication__subtitle {
                    text-decoration: underline;
                }
            }
        }
    }

    &__title {
        display: inline-block;
        vertical-align: top;
        font-weight: $bold;
        font-size: 3.8rem;
        line-height: 1.1;
        font-family: $typo-1;
        margin: 0 0 -0.6rem;

        span {
            position: relative;
            left: .7rem;
            display: block;
            text-align: right;
            color: $color-2--1;
            font-weight: $normal;
            font-size: 9.6rem;
            line-height: .1;
            font-family: cheddar-jack, sans-serif;
        }

    }

    &__num {
        text-transform: uppercase;
        font-weight: $black;
        font-size: 3.8rem;
        line-height: 1;
        font-family: $typo-1;
    }

    &__subtitle {
        font-weight: $light;
        font-size: 2.2rem;
        line-height: 1.2;
        font-family: $typo-2;
    }

    &__controls {
        .button-2 {
            margin-bottom: 0.3rem;
            white-space: nowrap;
        }
    }

    &__download {
        &.button-2.button-2__yellow {
            .ico {
                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__read {
        .external {
            @include default-icons-after('\e06d', 0, 1.1rem, inherit);

            &:after {
                position: relative;
                top: -1px;
                font-weight: $normal;
            }
        }
    }

    &_sm {
        color: $color-1--1;
    }

    &_sm &__img {
        width: 9.1rem;
        height: auto;
        background: none;
        margin: 0 1.7rem 0 0;
        text-align: right;

        img {
            position: static;
            width: auto;
            height: auto;
            max-width: 100%;
            border: 1px solid rgba(0, 0, 0, .2);
        }
    }

    &_sm &__img-inner {
        padding: 0;
        height: auto;
    }

    &_sm &__img-frame {
        position: static;
        left: auto;
        top: auto;
        width: auto;
        height: auto;
    }

    &_sm &__head {
        margin: 0 0 1.1rem;
    }

    &_sm &__content {
        padding: 0.9rem 0 0;
    }

    &_sm &__title {
        font-weight: $normal;
        font-size: 1.6rem;
        line-height: 1.1;
        font-family: $typo-2;
        text-transform: uppercase;
        margin: 0 0 .3rem;

        span {
            font-size: inherit;
            left: auto;
        }
    }

    &_sm &__num {
        font-size: 1.6rem;
    }

    &_sm &__subtitle {
        font-weight: $light;
        font-size: 1.3rem;
        line-height: 1.1;
        font-family: $typo-1;
    }

    &_sm &__controls {
        .button-2 {
            display: block;
            background: none;
            margin: 0 0 .1rem;
            padding: 0;
            font-weight: $bold;
            font-size: 1.1rem;
            line-height: 1;
            color: $color-1--1;
            white-space: nowrap;

            > span {
                font-weight: $bold;
                font-size: 1rem;
            }

            .ico {
                left: auto;
                width: 2.2rem;
                height: 2.2rem;
                margin: 0 0.1rem 0 0;
            }
        }
    }
}
