.event {
    &-unit {
        display: block;
        &__wrap {
            width: 100%;
            display: block;
            .event-unit__content {
                width: 100%;
                margin: 9px 0 0;
            }
        }
        &__img {
            width: 100%;
            margin: 0;
        }
    }
}

.event-unit__date,
.content .event-unit__date,.event-unit__wrap .event-unit__date {
    float: none;
    margin: -5.4rem 1rem 0 2.9rem;
}

.event-unit__date-item span,
.content .event-unit__date-item span {
    font-size: 1.6rem;
}