.publication {
    &__head {
        margin: 0 5rem 0 0;
        float: left;
    }
    &__controls {
        width: 232px;
        float: left;
        padding: 9px 0 0;
        .button-2 {
            &:after {
                content: '';
                display: inline-block;
            }
        }
    }
    &__content {
        padding-top: 29px;
    }
    &_sm {
        .publication__head {
            width: 100%;
            float: none;
            margin: 0;
        }
        .publication__controls {
            width: 100%;
            float: none;
        }
    }
}

.publication__img,
.content .publication__img {
    width: 22.3rem;
    margin-right: 2.1rem;
}

.publication_sm .publication__img {
    width: 9.3rem;
    margin-right: 1.7rem;
}