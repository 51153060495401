// @name bloc event

// @name Bloc event in column
.column-bloc.bloc-event {
    display: none;
    overflow: hidden;
    .bloc-event__picture {
        float: none;
        width: 100%;
        display: block;
        margin: 0;
    }
    .bloc-event__item {
        position: relative;
    }
    .bloc-event__wrapper-date {
        position: static;
    }
    .bloc-event__wrapper {
        padding-top: 10px;
    }
    .link-view {
        margin-top: 2.9rem;
    }
    .date-1 {
        margin: -7.8rem 0.9rem 3.3rem;
    }
}