.project-grand,
.content .project-grand {
    &-img {
        margin: 0 0 10px;
        &:after {
            width: 196px;
            height: 196px;
            right: -48px;
            bottom: -81px;
            background-image: url(../Images/pictos/decore-circle-6.png);
        }
    }
    &-description {
        padding: 37px 34px 48px;
        h3 {
            margin: 0 0 22px;
        }
        .title-4 {
            display: none;
        }
    }
}