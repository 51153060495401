.project-directives,
.content .project-directives {
    width: 300px;
    padding: 76px 0 0;
    margin: 0 auto 24px;
    &:before {
        top: 94px;
        left: 250px;
    }
    &:after {
        right: 102px;
        bottom: 21px;
    }
    &__item {
        width: 146px;
        margin: 0 2px 5px !important;
        font-size: 1.4rem;
        &_lead {
            width: 216px;
            height: 216px;
            top: 6px;
            left: -54px;
            &:before {
                width: 200px;
                height: 200px;
                top: -21px;
                right: 17px;
            }
            &:after {
                left: -4rem;
                bottom: -0.8rem;
            }
            .project-directives__item-content {
                padding: 3.9rem 0 4.3rem 5.1rem;
            }
        }
        .name {
            font-size: 1.9rem;
            padding: 0 0 1.1rem;
            margin: 0 0 0.7rem;
            &:before {
                width: 98px;
                right: 31px;
            }
        }
        .years {
            font-size: 2rem;
            text-align: left;
            padding: 0 0 0 37px;
        }
        &:last-child {
            &:before {
                width: 112px;
                height: 112px;
                top: -1.5rem;
                right: -4.5rem;
            }
        }
    }
    a {
        &:nth-of-type(1) {
            margin-left: 152px !important;
        }
        &:nth-of-type(2) {
            &:after {
                position: absolute;
                top: 17px;
                left: 50%;
                -webkit-transform: translate(-50%, 0);
                -ms-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
                width: 999px;
                height: 109px;
                background: url(../Images/bg/plus-pattern.png);
                z-index: -1;
                content: '';
            }
        }
    }
}