// @name site informations
.site-infos {
    float: left;
    width: 30%;
    margin-right: 2.8%;
    text-align: center;
    .site-infos__img-wrap {
        float: none;
        margin: 0 0 29px;
    }
}