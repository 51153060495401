.content-gallery {
    margin: 0 0 5.3rem;
    .listItems {
        margin: 0 2rem;
    }
    .item {
        &-content {
            width: 37.5rem;
        }
        &-subtitle {
            margin: 1.3rem 0 0.9rem;
        }
        &-title {
            font-size: 2.2rem;
            margin: 0;
        }
        &-person {
            margin: 0.5em 0 1.3rem 0;
        }
        &-decore {
            left: 38%;
            &-1 {
                width: 9.5rem;
                height: 9.5rem;
            }
            &-2 {
                width: 27rem;
                height: 27rem;
                bottom: -10.1rem;
                right: 23rem;
                left: auto;
            }
        }
    }
}