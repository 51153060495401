.list-type-1--event {
    .teaser-1 {
        margin-bottom: 0;
    }

    .list-type-1__item{
        padding: 2.3rem 0 2rem;
        position: relative;
        //border-bottom: 1px solid #bfbfbf;
        &::before {
            height: 1px;
            width: 24rem;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #bfbfbf;
        }
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
          &::before {
              content: none;
          }
        }
    }

    .list-type-1__title {
        margin-bottom: 1.5rem;

    }

    .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
        display: block;
        margin-bottom: 0;
        vertical-align: inherit;
    }
    .list-type-1__picture {
        img {
            margin-right: 0;
        }
    }
    .list-type-1__wrapper-date {
        padding-right: 0;
        border-right: none;
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: -6rem 0 1.5rem 1.5rem;
        align-items: flex-start;

        &.no-image {
            margin-top: 0;
        }

        .date-1 {
            time:nth-of-type(1) {
                margin-left: 0;
            }
        }
    }
    .list-type-1__wrapper {
        padding-left: 0;
    }
}
