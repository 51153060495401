// @name site informations
.site-infos {
    float: left;
    width: 38%;
    margin-right: 7.8%;
    .site-infos__img-wrap {
        float: left;
        margin: 6px 14.5% 0 0;
    }
    .site-infos__wrapper {
        overflow: hidden;
        font-style: normal;
    }
    p {
        font-size: 1.2rem;
        line-height: 1.2;
        font-weight: 300;
        margin: 0 0 23px;
        color: #fff;
        strong {
            font-size: 1.6rem;
            line-height: 1.2;
            color: #fcd808;
            font-weight: 700;
            display: block;
            margin: 0 0 4px;
        }
    }
    ul {
        > li {
            margin: 0.2em 0;
        }
    }
    .phone {
        color: #fff;
        font-size: 1.7rem;
        line-height: 1.2;
        display: inline-block;
        vertical-align: top;
        position: relative;
        font-weight: 700;
        padding: 0 0 0 24px;
        &:after {
            position: absolute;
            top: 50%;
            left: 2px;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            color: #fcd808;
            font-size: 18px;
            line-height: 1;
            font-weight: 400;
            font-family: icons-default;
            content: '\e02b';
        }
    }
}