.ddm {
    .filters__ddm {

    }
    .filters__ddm__sub-level {
        display: block;
        height: auto;
        overflow: hidden;
        max-height: 0;
        transition: max-height ease .2s;
    }
    &.ddm--active {
        .filters__ddm__sub-level {
            max-height: 100em;
            transition: max-height ease 1s;
        }
    }
}

.heading-filters {
    position: relative;
    .heading {
        padding-right: 45px;
    }
    .filters__ddm {
        top: 33px;
        position: absolute;
        right: 10px;
        z-index: 4;
        overflow: hidden;
        button {
            width: 40px;
            height: 40px;
        }
    }
}

.filters {
    .ddm {
        position: static;
    }
}

.filters__wrapper {
    &.filters__wrapper--in-line {
        .filters__field-wrapper {
            width: 100%;
            .filters__field-1, .filters__field-2 {
                display: block;
            }
            .filters__field-1 {
                text-align: left;
                width: 100%;
            }
            .filters__field-2 {
                width: 100%;
            }
        }
    }
}