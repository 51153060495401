// @name bloc contact

// @name Bloc contact in column
.column-bloc.bloc-contact {

    color: $color-1--1;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: (20 / 14);
    text-align: center;

    p {
        margin-bottom: 1.2rem;
    }

    img {
        max-width: 100%;
        height: auto;
        margin-bottom: 2em;
    }

    .title-3 {
        display: inline-block;
        vertical-align: top;
        margin: -1.6rem 0 2.3rem;
        font-weight: 700;
        color: #fff;
        font-size: 1.8rem;
        line-height: 1;
        background: $color-1--1;
        padding: 9px 17px;
    }

    .bloc-contact__inner {
        background: $color-2--1;
        padding: 0 2rem 3.8rem;
    }

    .bloc-contact__title {
        $bloc-contact__title__font-size: 1.6;
        font-family: $typo-3;
        font-weight: $bold;
        font-size: 1.6rem;
        color: $color-1--1;

        a {
            color: $color-1--1;
            &:hover, &:focus {
                color: $color-1--2;
            }
        }
    }

    .phone {
        @include default-icons-before('\e02b', 0 10px 0 0, 16px, inherit);

        font-weight: 700;
        font-size: 1.9rem;
        color: inherit;

        &:before {
            position: relative;
            top: -1px;
            font-weight: 400;
        }
    }

    .button-2 {

        &:before {
            content: "\e02a";
        }
    }

    .loc,
    .contact {
        padding-top: 1rem;
        margin: 0;
    }
    .contact {
        .button-2 {
            &:hover,
            &:focus {
                background: #fff;
                .ico {
                    background: #fcd808;
                    svg {
                        fill: #000;
                    }
                }
            }
        }
    }
    .site {
        padding-top: 1rem;

    }
}