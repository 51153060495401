.fast-access {
    margin-top: 2.1rem !important;
    margin-bottom: 1rem;
    padding: 0 0 3.4rem;
    background: $color-white url(#{$image-bg-url}main.png);
    text-align: center;

    a {
        background: none;
        padding: 0;
    }

    .listItems {
        margin: 0 5.6rem;
        display: flex;
        justify-content: center;
    }

    .item {
        vertical-align: top;
        opacity: 1 !important;
        padding: 0 20px;
    }

    .ico {
        position: relative;
        border-radius: 50%;
        width: 11.4rem;
        height: 11.4rem;
        margin: 0 auto 1.1rem;
        border: 10px solid $color-2--1;
        background: #fff;

        img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 4.2rem;
            height: 4.2rem;
        }
    }

    .item:hover {
        .ico {
            background-color: $color-2--1;
        }
    }

    .txt {
        text-transform: uppercase;
        color: $color-1--1;
        font: $normal 1.2rem/#{(16 / 12)} $typo-2;
    }

    .prevNextCaroussel3 {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .prevCaroussel3,
    .nextCaroussel3 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
        margin: -0.7rem 0 0;

        &:before {
            display: none;
        }

        img {
            display: none;
        }

        button {
            @include default-icons-after('', 0, 2rem, $color-1--1);

            &:after {
                font-weight: $normal;
            }
        }
    }

    .prevCaroussel3 {
        left: 0;

        button {
            &:after {
                content: '\e010';
            }
        }
    }

    .nextCaroussel3 {
        right: 0;

        button {
            &:after {
                content: '\e011';
            }
        }
    }

    &__carousel {
        position: relative;
        .cycle-carousel-wrap {
            .item {
                opacity: 1 !important;
                visibility: visible !important;
            }
        }
    }

    &__title,
    h2#{&}__title {
        display: inline-block;
        vertical-align: top;
        padding: 0.8rem 1.8rem;
        transform: translateY(-50%);
        background: $color-2--1;
        color: $color-1--1;
        font-weight: $bold;
        font-size: 1.8rem;
        line-height: 1;
        text-transform: uppercase;
        margin: 0 0 2rem;
    }
}