.social-networks {
    > span {
        display: inline-block;
        vertical-align: middle;
        color: $color-white;
        text-transform: uppercase;
        font: $light 1.2rem/1 $typo-2;
        margin: 0 2rem 0 0;
    }

    a {
        display: block;
        height: 100%;
        color: $color-white;
        &:focus {
            outline-color: $color-white;
        }
    }

    ul {
        display: inline-block;
        vertical-align: middle;
    }

    li {
        position: relative;
        float: left;
        width: 4.2rem;
        height: 3.2rem;

        &:not(:first-child) {
            border-left: 1px solid rgba(109, 110, 112, .5);
        }
    }

    svg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        fill: #FCD805;
    }

    .linkedin {
        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }

    .facebook {
        svg {
            width: .8rem;
            height: 1.6rem;
        }
    }

    .twitter {
        svg {
            width: 1.6rem;
            height: 1.2rem;
        }
    }

    .instagram {
        svg {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    .youtube {
        svg {
            width: 1.3rem;
            height: 1.6rem;
        }
    }
}