.technologies-carousel {
  position: relative;

  .listItems {
    margin: 0 4rem;
    display: flex;
    justify-content: center;
  }

  .item {
    padding: 0 2.4rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: $normal;
    font-size: 1.3rem;
    line-height: 1.1;
    font-family: $typo-2;
    background: none;
    max-width: 228px;
    width: 100%;
    &:after{
      content:''!important;
      display:block !important;
    }
    svg {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 1.4rem;
    }
  }

  .item-inner {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-white;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .item:hover {
    .item-inner {
      background-image: url(#{$image-pictos-url}decore-circle.png);
    }
  }

  .item-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 2rem;
  }

  .prevNextCaroussel3 {
    margin: 0;
    padding: 0;
  }

  .prevCaroussel3,
  .nextCaroussel3 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    margin: 0;

    &:before {
      display: none;
    }

    img {
      display: none;
    }

    button {
      @include default-icons-before('', 0, 2.4rem, $color-1--1);
    }
  }

  .prevCaroussel3 {
    left: 0;

    button {
      &:before {
        content: '\e010';
      }
    }
  }

  .nextCaroussel3 {
    right: 0;

    button {
      &:before {
        content: '\e011';
      }
    }
  }
}