//@name Page content

// max width for content element
$content__max-width: ($tablet/10) - $column-bloc__width - $column-bloc__padding-left;

// @name Title h2
$content-h2__font-size: 3.4;
.content h2, .h2 {
    font-family: $typo-3;
    font-size: 3.6rem;
    line-height: 1em;
    font-weight: $light;
    color: $color-1--2;
    margin: em(6, $content-h2__font-size) 0 2.3rem 0;
    a {
        color: $color-1--2;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h3
$content-h3__font-size: 2.5;
.content h3, .h3 {
    font-family: $typo-2;
    font-size: #{$content-h3__font-size}em;
    line-height: 1em;
    font-weight: $normal;
    color: $color-1--2;
    margin: em(5, $content-h3__font-size) 0 em(1.7, $content-h3__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

// @name Title h4
$content-h4__font-size: 1.8;
.content h4, .h4 {
    font-family: $typo-3;
    font-size: #{$content-h4__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: 3.7rem 0 2rem;
    padding: 0 0 0 2.2rem;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        background: $color-2--1;
        border-radius: 50%;
    }
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-2--3;
        }
    }
}

// @name Title h5
$content-h5__font-size: 1.7;
.content h5, .h5 {
    font-family: $typo-2;
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: $normal;
    color: $color-3--3;
    margin: 2.7rem 0 1.6rem 0;
    a {
        color: $color-3--3;
        &:hover, &:focus {
            color: $color-black;
        }
    }
}

// @name Title h6
$content-h6__font-size: 1.5;
.content h6, .h6 {
    font-family: $typo-3;
    font-size: #{$content-h6__font-size}em;
    line-height: 1em;
    color: $color-black;
    margin: em(2, $content-h6__font-size) 0 em(1, $content-h6__font-size) 0;
    a {
        color: $color-black;
        &:hover, &:focus {
            color: $color-3--3;
        }
    }
}

$content-list__font-size: 1.4;
.content {
    // @name Reset first element
    // @description Reset margin top for first element.
    > *:first-child {
        margin-top: 0;
    }

    // @name Link
    a {
        color: $color-1--2;
        text-decoration: none;
        background: $color-2--1;
        padding: 0 .1rem;

        &:hover, &:focus {
            text-decoration: none;
        }

        &[href="http://openlayers.org/"] {
            text-decoration: none;
            background: none;
            padding: 0;
        }
    }

    .external-image {
        background: none;
    }

    //  @name External link
    a[href^="http://"]:not([href*="#{$domain}"]):not(.button-2):not(.external-image),
    a[href^="https://"]:not([href*="#{$domain}"]):not(.button-2):not(.external-image),
    a[href^="//"]:not([href*="#{$domain}"]):not(.button-2):not(.external-image) {
        @include default-icons-after('\e086', 0 0 0 4px, 12px, $color-1--1, middle);

        background: $color-2--1;

        &:after {
            position: relative;
            top: -1px;
        }
    }

    // @name Paragraph
    $content-paragraph__font-size: 1.5;
    p {
        font-size: 1.5rem;
        line-height: 1.2;
        font-weight: $light;
        margin: em(.5, $content-paragraph__font-size) 0 0.3rem 0;
        color: $color-1--1;
    }

    // @name Content chapeau
    // @description Specific style for paragraph
    .chapeau {
        font-size: 1.4em;
        color: $color-3--3;
        a {
            color: $color-3--3;
        }
    }

    // @name Content encadre
    // @description Specific style for paragraph
    .encadre,
    .encadre--attention,
    .encadre--information,
    .encadre--download {
        position: relative;
        overflow: hidden;
        $encadre__font-size: 1.6;
        padding: 3.1rem 7rem 3.2rem 10rem;
        font-size: #{$encadre__font-size}em;
        background: $color-2--1;
        font-weight: $bold;
        margin: 2.06rem 0 2.4rem;
        line-height: 1.25;
        a {
            background: $color-white;
            color: inherit;
        }
        &:before {
            width: 6.4rem;
            height: 6.4rem;
            left: 2.4rem;
            position: absolute;
            background: url('#{$image-pictos-url}lamp.png') no-repeat;
            top: 50%;
            transform: translateY(-50%);
            content: '';
        }
    }
    .encadre--attention {
        &:before {
            background-image: url('#{$image-pictos-url}icon-encadre-attention.png');
        }
    }
    .encadre--information {
        &:before {
            background-image: url('#{$image-pictos-url}icon-encadre-information.png');
        }
    }
    .encadre--download {
        &:before {
            background-image: url('#{$image-pictos-url}icon-encadre-download.png');
        }
    }

    // @name Blockquote
    $content-blockquote__font-size: 1.8;
    $content-blockquote-icon-1__font-size: 3.2;
    blockquote {
        position: relative;
        font-size: 2rem;
        padding: 1rem 4rem 1rem 6rem;
        font-weight: $normal;
        margin: 3.1rem 0 3.3rem;
        color: $color-1--1;
        text-transform: uppercase;
        font-family: $typo-2;

        .ico {
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            border-radius: 50%;
            width: 3.9rem;
            height: 3.9rem;
            border: 3px solid $color-2--1;

            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background: $color-3--3;
                width: 6px;
                height: 6px;
                box-shadow: 7px 0 0 $color-3--3, -7px 0 0 $color-3--3;
            }
        }

        p {
            font-size: 1em;
            font-weight: $normal;
            font-family: $typo-2;
            line-height: 1.11;
            color: $color-1--1;
            margin: 0 0 0.9rem;
        }

        cite {
            display: block;
            font-weight: $light;
            font-family: $typo-1;
            font-size: 1.1rem;
            font-style: normal;
            text-transform: none;
        }
    }

    ul.attribution-links {
        > li {
            a {
                background: transparent !important;

                &::before,
                &::after {
                    display: none !important;
                }
            }
        }
    }

    // @name Unordered list
    ul, .show-hide__content ul {
        margin: 1.6rem 0 3rem 2.5rem;
        list-style: none;
        font-size: 1.5rem;
        color: $color-black;
        padding: 0;
        font-weight: $light;
        font-family: "open sans", arial, helvetica, sans-serif;
        > li {
            @include default-icons-absolute-before('\e027', 1.1rem, $color-2--2, 0.4rem, inherit, inherit, 0);
            padding-left: 1.3rem;
            margin: 0 0 .3rem;

            button, .button-1, .button-1.button-1--small, .button-1.button-1--big, .button-2, .button-2.button-2--small, .button-2.button-2--big {
                font-size: 1em;
            }

            > ul:first-child,
            > a + ul { // for specificity selector
                margin: -0.2rem 0 0.1rem 0.8rem;
                list-style: none;
                padding: 0;

                > li {
                    @include default-icons-absolute-before('\e007', em(1, $content-list__font-size), $color-black, em(.5, 1), inherit, inherit, 0);
                    padding-left: 1.2rem;
                    margin-bottom: -0.1rem;
                }
            }
            > ol:first-child, > a + ol { // for specificity selector
                margin: -0.3rem 0 0 2.8rem;
                padding: 0;
                font-size: inherit;
                > li {
                    padding: 0 0 0 0.4rem;
                    margin: 0 0 -0.3rem;
                    &:before {
                        font-family: none;
                        content: none;
                    }
                }
            }
        }
    }

    // @name Ordered list
    ol, .show-hide__content ol {
        margin: 0 0 0 4rem;
        padding: 0;
        list-style: decimal;
        color: $color-black;
        font-weight: $light;
        font-family: "open sans", arial, helvetica, sans-serif;
        font-size: 1.5rem;
        > li {
            padding: 0 0 0 0.5rem;
            margin: 0;
            button, .button-1, .button-1.button-1--small, .button-1.button-1--big, .button-2, .button-2.button-2--small, .button-2.button-2--big {
                font-size: 1em;
            }
            > ol:first-child,
            > a + ol { // for specificity selector
                margin: -0.4rem 0 0.3rem 2.1rem;
                padding: 0;
                font-size: 1em;
                > li {
                    padding: 0 0 0 0.4rem;
                    margin: 0 0 -0.4rem;
                }
            }

            > ul:first-child,
            > a + ul { // for specificity selector
                margin: -0.3rem 0 0 0.2rem;
                list-style: none;
                padding: 0;
                font-size: 1em;
                > li {
                    @include default-icons-absolute-before('\e007', em(1, $content-list__font-size), $color-black, em(.5, 1), inherit, inherit, 0);
                    margin: 0;
                }
            }
        }
    }

    // @name Reset list
    // @description for reset list ul or ol in content
    .reset__list {
        margin: auto;
        padding: 0;
        list-style: none;
        font-size: 1em;
        > li {
            padding: 0;
            &:before {
                content: "";
                margin: auto;
            }
            > ol, > ul {
                padding: 0;
                > li {
                    padding: 0;
                    &:before {
                        content: "";
                        margin: auto;
                    }
                }
            }
        }
    }

    // @name Table
    .table-wrapper {
        margin: 4rem 0 6rem;
    }
    .table-wrapper-inner {
        overflow-y: auto;
        width: 100%;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, .5);
            -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
    }
    table {
        width: 100%;
        font-size: 1em;
        border: 10px solid $color-2--1;
        border-collapse: separate;
        background: $color-white;
        ul {
            font-size: 1em;
        }
        caption {
            $table__caption_font-size: 1.6;
            font-family: $typo-2;
            text-align: left;
            font-weight: $light;
            font-size: 1.6rem;
            color: $color-3--3;
            margin-bottom: 1.5rem;
        }
        th, td {
            padding: 1.3rem 0.8rem 1.2rem;

            &:first-child {
                padding-left: 2.8rem;
            }

            &:last-child {
                padding-right: 2.8rem;
            }
        }
        th {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-family: $typo-2;
            font-weight: $bold;
            color: $color-1--1;
            text-align: left;

            &:first-child {
                padding-right: 3.3rem;
            }

        }
        thead th {
            font-size: 1.5rem;
            font-weight: $bold;
            color: $color-1--2;
            padding-top: 2.8rem;
            padding-bottom: 1.5rem;
        }
        tbody th {
            text-align: right;
        }
        td {
            font-size: 1.5rem;
            color: $color-1--1;
        }

        thead {
            .sep {
                td {
                    div {
                        height: .6rem;
                        background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
                    }
                }
            }
        }

        tbody {
            tr:last-child {
                th,
                td {
                    padding-bottom: 2.7rem;
                }
            }
        }

        .sep {
            td {
                padding: 0 2.8rem;

                div {
                    height: 1px;
                    background: $color-3--1;
                }
            }
        }
    }

    // @name column 2
    .column--2, .column--3 {
        margin: 2.1rem 0 3.8rem;
        column-gap: 2em;
    }
    .column--2 {
        columns: 2;
    }
    .column--3 {
        columns: 3;
    }
}

@media (max-width: 767px) {
    .content {
        .ol-attribution {
            .ol-logo-only {
                height: 2.1rem;
            }
        }
    }
}

.locate {
    margin: 7.2rem 0;
    font-family: $typo-1;

    .map-holder {
        position: relative;
        overflow: hidden;
        background: #f6f6f6;

        img {
            width: calc(100% - 220px);
        }
    }

    .title {
        text-transform: uppercase;
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2.8rem;
        line-height: 1;
        position: relative;
        padding: 0 0 0 6.4rem;
        margin: 0 0 2.6rem;
        color: $color-1--1;

        .ico {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            background: $color-2--1;
            width: 5.2rem;
            height: 5.2rem;

            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto !important;
                width: 25px;
                height: 26px;
                stroke: $color-1--1;
                fill: none;
                stroke-width: 4px;
            }
        }
    }

    .sidebar-info {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 22rem;
        padding: 2.9rem 3.4rem;
        color: $color-black;

        h4 {
            padding: 0;

            &:before {
                content: none;
            }
        }

        .link-holder {
            margin: 0 0 9px;
            overflow: hidden;

            p {
                padding: 0 0 0 1.4rem;
                margin: 0 0 0.8rem;
                position: relative;

                &:after {
                    position: absolute;
                    top: 0;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-family: "icons-default";
                    font-size: 1.1rem;
                    line-height: 1;
                    color: $color-2--1;
                }

                &.phone {
                    &:after {
                        content: "\e02b";
                    }
                }

                &.fax {
                    &:after {
                        content: "\e088";
                    }
                }
            }

            a {
                text-decoration: none;
                font-weight: $light;
                color: inherit;
                background: none;

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    .adr {
        display: block;
        margin: 0 0 0.7rem;
        font-weight: $light;
        font-style: normal;
        h4 {
            display: block;
            font-size: 2rem;
            margin: 0 0 0.8rem;
            color: #000;
        }
        span {
            display: block;
            font-size: 1.4em;
            font-weight: $light;
        }
    }

    .btns {
        max-width: 140px;
        position: absolute;
        bottom: 3.9rem;
        left: 3.4rem;
        right: 3.4rem;
    }
    .sidebar-info .button-2 {
        padding: 0.7rem 0.7rem 0.5rem;
        font-size: 1.5rem;
        display: block;
        font-weight: $normal;

        &:not(:last-child) {
            margin-bottom: 0.8rem;
        }

        .ico {
            margin-right: 0.9rem;

            span {
                &:before {
                    @extend .icons-default;

                    color: $color-1--1;
                    font-size: 1.1rem;
                }
            }

            &__contact {
                &:before {
                    content: '\e02a' !important;
                }
            }

            &__search {
                &:before {
                    content: '\e034' !important;
                }
            }
        }
    }
}

.downloads {

    a {
        background: none;
    }

    .downloads-block {
        padding: 4.3% 3.7% 4.9%;
        border: 10px solid $color-2--1;
    }

    .title {
        text-transform: uppercase;
        font-family: $typo-1;
        font-weight: $bold;
        font-size: 2.8rem;
        line-height: 1;
        position: relative;
        padding: 0 0 0 6.4rem;
        margin: 0 0 2.8rem;
        color: $color-1--1;

        .ico {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            background: $color-2--1;
            width: 5.2rem;
            height: 5.2rem;

            svg {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto !important;
                width: 26px;
                height: 24px;
                stroke: $color-1--1;
                fill: none;
                stroke-width: 4px;
            }
        }
    }

    .downloads-list {
        margin: 0;
        padding: 0;
        display: block;
        vertical-align: top;
        position: relative;
        font-family: $typo-2;

        &:before {
            content: '';
            position: absolute;
            left: 48.2%;
            top: -4px;
            bottom: -4px;
            width: 1px;
            background: #afafaf;
        }

        li {
            display: inline-block;
            font-size: 1.6rem;
            line-height: 1;
            margin: 0 0 2.9rem 0;
            min-height: 38px;
            padding: 0 6% 0 0;
            position: relative;
            vertical-align: top;
            width: 51.4%;
            word-break: break-word;

            &:before {
                content: none;
            }

            &:nth-child(2n) {
                padding: 0;
                width: 42%;
            }

            &:nth-last-child(2),
            &:last-child {
                margin: 0;
            }

            a {
                padding-left: 41px;
                display: block;
                text-decoration: none;
                color: $color-black;
                font-weight: $light;
                font-family: $typo-2;
                position: relative;

                &:hover {
                    text-decoration: underline;
                }

                &:focus {
                    outline: 1px dashed $color-black;
                    outline-offset: -1px;
                }

                svg {
                    position: absolute;
                    left: 0;
                    top: -4px;
                    width: 3.1rem;
                    height: 3.9rem;
                    fill: $color-3--3;
                }

                span {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.menu-index {
    a {
        background: none;
        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}
