.interactive {
    &-map {
        padding: 0;
        &:before {
            height: 18rem;
        }
        &__inner {
            display: block;
            flex-wrap: inherit;
            > * {
                display: block;
            }
        }
        &__info {
            margin-bottom: 0;
            &-unit {
                &-title {
                    width: 100%;
                    float: none;
                    text-align: left;
                    margin: 0 0 2rem;
                    min-height: 0;
                }
                &-content {
                    p {
                        font-size: 1.3rem;
                        line-height: 1.2;
                    }
                }
            }
        }
        &__territory-wrap,
        .content &__territory-wrap {
            padding-bottom: 100%;
        }
        &__communes, &__territory, &__info {
            width: auto;
            float: none;

        }
        &__communes {
            order: 2;
            margin-top: 1rem;
            padding: 0 1.7rem;
        }
        &__territory {
            order: 1;
        }
        &__info {
            order: 3;
        }
    }
}

.content .interactive-map {
    &__territory {
        width: 100%;
    }
    &__communes {
        margin-top: 0;
    }
}

.interactive-map__territory,
.content .interactive-map__territory {
    width: 100%;
    float: none;
    padding: 0;
    margin: 0;
}

.interactive-map__communes-list li,
.content .interactive-map__communes-list li {

}

.interactive-map__territory-wrap,
.content .interactive-map__territory-wrap {
    //height: auto;
}

.interactive-map__communes-list,
.content .interactive-map__communes-list {
    margin: 0 -13px;
    li {
        margin: 0 0 .8rem;
    }
}

.interactive-map__communes-title,
.content .interactive-map__communes-title {
    min-height: 0;
}