.technologies {
    &-carousel {
        .listItems {
            width: 26rem !important;
            margin: 0 auto;
        }
        .item {
            padding: 0 4.2rem;
        }
    }
}