.images {
    background: $color-1--1;

    &__main {
        @extend .clear-fix;

        margin: 0 0 3.8rem;
    }

    &__col {
        float: left;
        min-height: 1px;
        width: (1 / 3 * 100%);
    }

    &__item,
    .content &__item {
        overflow: hidden;
        position: relative;
        display: block;
        padding: 0;
        background: $color-3--3;

        &_sm {
            width: 50%;
            float: left;
        }
    }

    &__pic {
        height: 0;
        padding-bottom: (308 / 406 * 100%);

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        opacity: 0;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: opacity .3s;
        background: rgba(236, 202, 33, .8);
        font-weight: $light;
        font-size: 1.6rem;
        line-height: 1.1;
        text-align: center;

        p {
            font-size: inherit;
            margin: 0;
        }
    }

    &__item:hover &__content,
    &__item:focus &__content {
        opacity: 1;
    }

    &__content-inner {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 2rem;
        width: 100%;
    }

    &__title {
        white-space: normal !important;
    }

    &__title,
    .content &__title {
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: $normal;
        font-size: 2rem;
        line-height: 1;
        font-family: $typo-2;
        padding: 0 1.2rem 2rem;
        margin: 0 0 1.7rem;
        background: url(#{$image-bg-url}sep.png) 0 100% repeat-x;
    }

    &__play {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__item:hover &__play,
    &__item:focus &__play {
        display: none;
    }

    &__item_sm &__content-inner {
        padding: 0.8rem;
    }

    &__item_sm &__title {
        font-size: 1.5rem;
        padding: 0 .8rem 1.4rem;
        margin: 0 0 1rem;
    }

    &__item_sm &__content {
        font-size: 1.2rem;
    }
    .cycle-carousel-wrap {
        .item {
            opacity: 1 !important;
            visibility: visible !important;
        }
    }
}