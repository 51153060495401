// @name Search box
// @description Search module
.search-box {
    display: none;
    float: right;
    position: absolute;
    top: -18px;
    left: 225px;
    right: -6px;
    height: 121px;
    background: rgba(17, 18, 18, 0.9);
    color: $color-1--1;
    z-index: 100;

    .header & {
        &__button {
            background: none;
            .svg-icon {
                display: none;
            }
            &:before {
                content: '\e057';
                color: #fff;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
            }
            &:focus {
                outline: 1px dotted #fff;
            }
        }
    }

    &.active {
        display: block;
    }

    form {
        background: none;
        margin: 0;
        position: absolute;
        left: 0;
        right: 6rem;
        top: 45.3%;
        padding: 0 0 0 45px;
        transform: translateY(-50%);
    }
    label {
        margin-right: em(0.5, 1.8);
        white-space: nowrap;
        font-size: 1.6rem;
        color: #fff;
        text-transform: uppercase;
        font-family: $typo-2;
    }

    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: inline-block;
        vertical-align: bottom;
    }

    // @name Search Field wrapper
    // @state .search__field-wrapper--in-line - Indicates label and field are side by side.
    .search-box__field-wrapper {
        &.search-box__field-wrapper--in-line {
            display: table;
            width: 100%;

            label {
                display: table-cell;
                vertical-align: middle;
                text-align: right;
                padding-right: 50px;
                width: 1%;
            }

            input,
            select {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }

            input {
                height: 44px;
                background: #fff;
                color: #000;
                font-size: 1.7rem;
                font-family: $typo-1;
                font-weight: 300;
                padding: 0;
                border-color: transparent transparent #fff transparent;
                &:focus {
                    outline: 1px dotted #fff;
                }
            }
        }
    }

    // Drop down menu
    .ddm {
        position: static;
        > .ddm__sub-level {
            display: block !important;
            position: static !important;
            top: 0;
            right: 3em;
            left: 0;
            width: 100%;
        }
    }

    &__fields-wrapper {
        width: calc(100% - 12.2rem);
        padding-right: 1rem;
    }

    &__close {
        position: absolute;
        border-radius: 50%;
        right: 49px;
        top: 49%;
        width: 3.8rem;
        height: 3.8rem;
        border: 3px solid $color-3--2;
        background: $color-white;
        cursor: pointer;
        padding: 0;
        overflow: hidden;
        transform: translate(0, -50%);
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 2px;
            height: 12px;
            background: #797979;
            margin: auto;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:focus {
            outline: 1px dotted #fff;
        }
    }

    &__button {
        border-radius: 50%;
        background: $color-2--1;
        width: 4.5rem;
        height: 4.5rem;

        svg {
            fill: $color-1--1;
        }

        &:hover {
            background: $color-1--1;

            svg {
                fill: $color-2--1;
            }
        }
    }
}

// @name Search list
.pertinence {
    font-size: 1.2em;
    span {
        font-size: em(1, 1.2);
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, .pertinence span {
        font-size: 1.1em;
        font-weight: $bold;
    }
}

meter {
    -webkit-appearance: meter;
    -moz-appearance: meterbar;
    width: em(6, 1.2);
    height: em(1, 1.2);
    border: 1px solid darken($color-3--1, 20%);
    &::-webkit-meter-bar {
        background: darken($color-3--1, 20%);
        height: 100%;
        width: 100%;
    }
    &::-webkit-meter-optimum-value {
        height: 100%;
        background: $color-1--2;
    }
    &:-moz-meter-optimum {
        background: darken($color-3--1, 20%);
        border: none;
    }
    &:-moz-meter-optimum::-moz-meter-bar {
        background: $color-1--2;
    }
    &:-moz-meter-sub-optimum::-moz-meter-bar {
        background: antiquewhite;
    }
    &:-moz-meter-sub-sub-optimum::-moz-meter-bar {
        background: steelblue;
    }
}

@media all and (-ms-high-contrast: none) {
    *::-ms-backdrop, meter {
        display: none;
    }
}
