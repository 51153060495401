.results {
    padding: 55px 0 62px;
    border-width: 20px;
    &-inner {
        display: block;
        padding: 0 5.2%;
    }
    &-block {
        width: 100%;
        &:not(:last-child) {
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: #111212;
            margin: 0 0 50px;
            padding: 0 0 51px 0;
        }
        &-info {
            width: 50%;
            &:first-child {
                width: 50%;
            }
        }
        &-chart {
            padding: 0;
            &-title {
                margin: 0 0 8px;
            }
        }
    }
    .title {
        font-size: 3.4rem;
        margin: 0 0 45px;
    }
    .answerChart {
        .list-pie-chart {
            float: right;
            width: 59.6%;
        }
    }
}