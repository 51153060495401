.go-to-top {
    margin-top: -4.1em;
    display: block;
    width: 100%;
    position: relative;
    right: 1%;
    bottom: 0;
    z-index: 7000;
    p {
        text-align: right;
    }
    a {
        overflow: hidden;
        &:before {
            content: "\e015" !important;
        }
    }
}