.mobile-menu {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    transform: translateX(100%);
    transition: transform .3s;

    .mobile-menu-active & {
        transform: none;
    }

    &-top {
        overflow: hidden;
        background: $color-1--1;
        padding: 1.5rem 1.8rem 1.8rem;
    }

    .btn-menu {
        display: block;
        float: left;
        border-radius: 0;
        background: none;
        text-transform: uppercase;
        color: $color-white;
        padding: 0 0 0 2.6rem;
        font-size: 1.6rem;
        line-height: 1;
        font-family: $typo-2;
        width: auto;
        height: auto;
        margin: 1.2rem 0 0 0.3rem;

        i {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $color-white;

            &:before,
            &:after {
                background: $color-white;
            }
        }
    }

    .btn-close {
        position: relative;
        float: right;
        width: 3.8rem;
        height: 3.8rem;
        border-radius: 50%;
        background: #555;
        border: 2px solid $color-3--3;

        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 2px;
            height: 13px;
            background: $color-white;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    .search-box {
        display: block !important;
        position: static;
        background: $color-1--1;
        float: none;
        height: auto;
        padding: 4.7rem 2rem 6.9rem;

        form {
            position: static;
            transform: none;
        }

        .search-box__close {
            display: none !important;
        }

        .search-box__fields-wrapper {
            padding-right: 0;
        }

        .search-box__field-wrapper {
            vertical-align: bottom;

            label {
                color: $color-white;
                text-transform: uppercase;
                display: block;
                width: auto;
                font-size: 1.6rem;
                font-weight: $normal;
                font-family: $typo-2;
                text-align: left;
                margin: 0 0 .7rem;
                padding: 0;
            }

            input {
                display: block;
                background: none;
                border-bottom: 1px solid $color-white;
                color: #777;
                font-size: 1.4rem;
                font-weight: $light;
                font-family: $typo-1;
                padding: 0 !important;
                height: 4rem;

                ::-webkit-input-placeholder {
                    color: $color-3--3;
                }

                ::-moz-placeholder {
                    opacity: 1;
                    color: $color-3--3;
                }

                :-ms-input-placeholder {
                    color: $color-3--3;
                }
            }
        }

        .ddm__sub-level {
            background: none !important;
            padding: 0 !important;
        }

        .search-box__button {
            width: 5.5rem;
            height: 5.5rem;

            &:hover {
                background: $color-white;
            }

            svg {
                width: 16px;
                height: 16px;
            }
        }

        .search-box__button-wrapper {
            vertical-align: bottom;
            padding-left: .8rem;
        }
    }

    .social-networks {
        background: #242626;
        text-align: center;

        > span,
        ul {
            float: none;
            display: inline-block;
            vertical-align: top;
        }

        > span,
        li {
            height: 3.9rem;
            line-height: 3.9rem;
        }

        > span {
            border-left: 0;
            padding: 0 1rem;
        }

        li {
            &:last-child {
                border-right: 1px solid rgba(109, 110, 112, 0.5);
            }
        }
    }

    .mob-nav {
        .menu-main__drop-close {
            display: none !important;
        }

        .menu-main__level-1 {
            > li {
                float: none;
                padding: 0 1rem;

                > .title > a,
                > a {
                    display: block;
                    text-transform: uppercase;
                    color: $color-black;
                    font-weight: $normal;
                    font-size: 1.8rem;
                    line-height: 1;
                    font-family: $typo-2;
                    padding: 1rem 1.8rem;
                    margin-right: 5.4rem;

                    &:hover {
                        text-decoration: none;
                    }
                }

                > .title {
                    position: relative;
                    padding: 2.1rem 0;

                    .toggle {
                        @include default-icons-absolute-before('\e018', 14px, $color-2--1);

                        position: absolute;
                        right: 0;
                        top: 2.1rem;
                        bottom: 2.1rem;
                        width: 5.4rem;
                        border-left: 1px solid $color-1--1;
                        font: 0/0 a;

                        &:before {
                            content: '\e018';
                            left: 50%;
                            top: 50%;
                            right: auto;
                            bottom: auto;
                            transform: translate(-50%, -50%);
                            font-weight: $normal;
                        }
                    }
                }

                &.active {
                    background: $color-2--1;

                    > .title {
                        .toggle {
                            &:before {
                                content: '\e019';
                                color: $color-1--1;
                            }
                        }
                    }
                }
            }
        }

        .menu-main__drop {
            position: static;
            border-width: 0 0 10px;
            padding: 2.1rem 1.7rem;
            font-size: 1.5rem;
        }

        .menu-main__col {
            display: block;
            float: none;
            width: auto;
            padding: 0;
        }

        .menu-main__section-title {
            padding: 0 0 1.8rem;
            margin-bottom: 1.6rem;
            display: block;

            &:after {
                width: 12.8rem;
            }

            a {
                display: block;
                margin: 0 4rem 0 0;
            }

            .toggle-2 {
                @include default-icons-absolute-before('\e08a', 15px, $color-3--3);

                position: absolute;
                right: 0;
                top: -0.9rem;
                border-radius: 50%;
                width: 3.3rem;
                height: 3.3rem;
                background: $color-white;
                border: 2px solid #d3d3d3;

                &:before {
                    bottom: auto;
                    right: auto;
                    left: 50%;
                    top: 48%;
                    transform: translate(-50%, -50%);
                    font-weight: $normal;
                }
            }
        }

        .menu-main__section.active {
            .toggle-2 {
                border: 0;
                background: $color-2--1;

                &:before {
                    content: '\e023';
                    color: #3c3c3b;
                }
            }
        }

        .menu-main__section {
            margin-bottom: 0 !important;
        }

        .menu-main__section-links {
            padding: 0 0 2.6rem;

            li {
                margin-bottom: .8rem;
            }

            a {
                padding-left: 2.2rem;

                &:before {
                    top: .7rem;
                }
            }
        }
    }
}

.mobile-menu-overlay {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);

    .mobile-menu-active & {
        display: block;
    }
}