// @name Wrapper with wide 940px
.wrapper-940 {
    width: 100%;
}

.wrapper-1160 {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
}

.column-bloc {
    width: 100%;
    padding-left: 0;
    margin-bottom: 4.4rem;
}

.home {
    .content {
        .wrapper-1220 {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
}

.filters {
    .wrapper-1220 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}