.top-bar {
    position: relative;
    z-index: 1;
    background: $color-1--1;
    padding: 0 1rem;

    .social-networks {
        margin-right: -1rem;
    }

    &__info {
        font-size: 1.1rem;
        padding: 1.3rem 0 1rem;

        strong {
            font-weight: $normal;
        }

        img {
            width: 7.8rem;
            margin-right: 1.1rem;
        }
    }
}