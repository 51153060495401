// @name Search box
// @description Search module
.search-box {
    width: auto;
    .search-box__fields-wrapper, .search-box__button-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .search-box__fields-wrapper {
        width: 100%;
    }
    .search-box__button-wrapper {
        padding-left: 1em;
    }
    // Drop down menu
    .ddm {
        > .ddm__sub-level {
            padding: 1em;
            background: $color-3--2;
        }
    }
    form {
        padding: 0;
    }
}