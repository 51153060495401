.interactive {
    &-map {
        padding: 0 0 10px;
        &:before {
            height: 16rem;
        }
        &__inner {
            flex-wrap: wrap;
        }
        &__info {
            order: 3;
            flex-basis: 100%;
            margin: 2rem -1rem 0;
            padding: 0 1rem;
            &-unit {
                text-align: left;
                &.hide {
                    margin-top: 0;
                }
                &-title {
                    width: 25%;
                    float: left;
                    margin: 0 4.3% 0 0;
                    text-align: right;
                    padding: 0;
                }
                &-content {
                    overflow: hidden;
                    p {
                        line-height: 1.1;
                    }
                }
            }
        }
        &__territory {
            order: 1;
            flex-basis: 54%;
            padding: 0 0 0 2rem;
        }
        &__communes {
            order: 2;
            flex-basis: 42%;
            overflow: hidden;
        }
        &__territory-wrap,
        .content &__territory-wrap {
            margin: 0;
        }
    }
}

// override .content - Why .content here? .content styling the content that comes form the RTE
.content .interactive-map {
    &__territory {
        flex-basis: 54%;
        padding: 0 0 0 2rem;
    }
    &__communes {
        margin-top: -2rem;
    }
}

.interactive-map__communes-list li,
.content .interactive-map__communes-list li {
    margin: 0 0 .8rem;
}

.interactive-map__territory-wrap:before,
.content .interactive-map__territory-wrap:before {
    width: 92%;
    height: 102%;
}