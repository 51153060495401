.contact-list,
.content .contact-list {
    font-style: normal;
    margin: 0;
    li {
        padding: 0 0 0 36px;
        position: relative;
        margin: 0 0 14px;
        font-size: 1.3rem;
        line-height: 1.2;
        font-weight: 400;
        &:before {
            display: none;
        }
        a {
            color: #111212;
            background: none;
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        &.contact-list-address {
            .contact-list-ico {
                &:after {
                    content: '\e028';
                }
            }
        }
        &.contact-list-schedule {
            .contact-list-ico {
                &:after {
                    content: '\e05b';
                }
            }
        }
        &.contact-list-phone {
            .contact-list-ico {
                &:after {
                    content: '\e02b';
                }
            }
        }
        &.contact-list-fax {
            .contact-list-ico {
                &:after {
                    content: '\e088';
                }
            }
        }
        &.contact-list-email {
            a {
                text-decoration: underline;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
            .contact-list-ico {
                &:after {
                    content: '\e02a';
                }
            }
        }
        &.contact-list-internet {
            .contact-list-ico {
                &:after {
                    content: '\e089';
                }
            }
        }
    }
    &-ico {
        width: 28px;
        height: 28px;
        position: absolute;
        top: -7px;
        left: 0;
        background: #fcd808;
        border-radius: 50%;
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            font-size: 14px;
            line-height: 1;
            font-family: icons-default;
            content: '';
        }
    }
}