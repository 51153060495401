.event-popup {
    &-banner {
        margin-bottom: -20px;
    }
    &-bottom-bar {
        padding: 10px 10px 15px;
        &-l {
            display: flex;
            justify-content: flex-end;
            a {
                align-self: flex-end;
            }
            &:after {
                display: none;
            }
        }
        a {
            padding: 0 5px;
        }
        &-r {
            margin: 0;
            padding: 0 0 0 20px;
        }
    }
    &-close {
        top: -50px;
        right: 0;
    }
}