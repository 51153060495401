.column-bloc.bloc-contact {
    font-size: 1.3rem;
    .bloc-contact__wrapper {
        display: flex;
        justify-content: space-between;
    }
    .bloc-contact-col-address {
        width: 48%;
    }
    p {
        margin-bottom: 1.7rem;
    }
    .phone {
        font-size: 1.7rem;
    }
    .bloc-contact-col-btn {
        width: 34%;
        text-align: left;
        p {
            padding-top: 0.5rem;
        }
    }
    .bloc-contact__inner {
        padding: 0 2rem 1.7rem;
    }
}