// @name bloc news

// @name Bloc news in column
.column-bloc.bloc-news {
    overflow: hidden;
    .bloc-news__picture {
        float: left;
        width: 300px;
        margin: 0 40px 0 0;
        img {
            margin: 0;
            display: block;
            width: 100%;
            height: auto;
        }
    }
    .bloc-news__wrapper {
        padding-top: 19px;
    }
    .bloc-news__title {
        font-size: 2rem;
        line-height: 1.2;
        margin: 0.7rem 0 2.6rem;
    }
}