.results {
    padding: 32px 0 68px;
    border-width: 15px;
    &-inner {
        padding: 0 3.7%;
    }
    &-block {
        display: block;
        width: 100%;
        &:not(:last-child) {
            padding: 0 0 89px 0;
            margin: 0 0 42px;
        }
        &-info {
            width: 100% !important;
            font-size: 1.6rem;
            padding-right: 0;
            &:first-child {
                margin-bottom: 53px;
            }
        }
        &-chart {
            padding: 0;
            &-title {
                margin: 0 0 17px;
            }
        }
    }
    .title {
        font-size: 3rem;
    }
    .answerChart {
        margin: 21px 0 0 0;
        #pieChartTarget {
            float: none;
            margin: 0 auto;
        }
        .list-pie-chart {
            width: 100%;
            float: none;
            padding: 16px 0 0;
        }
    }
    .radio {
        label {
            font-size: 1.4rem;
            line-height: 1.6;
        }
    }
}