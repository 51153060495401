.header {
    padding-bottom: 0;
    .btn-menu {
        float: right;
        display: block;
    }

    .menu-main-wrap,
    .btn-search {
        display: none;
    }

    &__inner {
        display: table;
        width: 100%;
    }

    &__inner-t {
        display: table-footer-group;
    }

    &__inner-b {
        display: table-header-group;
    }

    &__nav-bar {
        position: relative;
        min-height: 0;
        padding: 1rem 3rem;

        &:before,
        &:after {
            transform: none;
            height: auto;
            margin: 0;
            left: auto;
            right: auto;
            top: auto;
        }

        &:before {
            right: -3.1rem;
            bottom: -1.2rem;
            width: 22.2rem;
            height: 14.5rem;
            background-image: url(#{$image-pictos-url}decore-circle-3.png);
        }

        &:after {
            display: table;
            clear: both;
            position: static;
            background: none;
        }

        .lang-switch {
            display: block;
            float: left;
            width: 4.9rem;
            margin: .1rem 0 0;
        }
    }
    &-no-image {
        .hero__img {
            height: 30px;
        }
    }
}

.top-of-content {
    display: none;
}

.section-main__wrapper {
    padding-top: 4.3rem;
    &-2 {
        flex-wrap: wrap;
    }
}

.home {
    .section-main__content {
        padding-bottom: 0;
    }
}

.section-main__content {
    padding-bottom: 6.2rem;
    &-no-indent {

    }
}

.section-main__aside {
    padding: 5.2rem 0 0;
    width: 100%;
}

.footer {
    padding: 16px 0 21px;
}