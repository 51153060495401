.list-type-1--event {
    .list__item {
        &.list__item--active {
            .list-type-1__picture {
                img {
                    filter: brightness(50%);
                }
            }
        }
    }
    .list-type-1__picture, .list-type-1__wrapper-date, .list-type-1__wrapper {
        display: table-cell;
        vertical-align: top;
    }
    .list-type-1__picture {
        float: none;
        img {
            max-width: 240px;
            transition: filter ease .2s;
        }
    }
    .list-type-1__wrapper-date {
        padding-right: 2em;
        width: 13em;
        border-right: 1px solid $color-3--3;
    }
    .list-type-1__wrapper {
        padding-left: 2em;
    }
    .date-1 {
        width: 14.5rem;
        height: 14.5rem;
        margin: 0 0.5rem 0.5rem;
        display: block;
        padding-top: 2.5rem;
        time {
            display: inline-block;
        }
    }
    .hour {
        margin-top: 1rem;
    }
}
