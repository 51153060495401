// @name Main section wrappers
.section-main__wrapper {
    width: 100%;
    padding-top: 2.6em;
    padding-bottom: 3em;
    margin-bottom: 7.6rem;
}

.section-main__content {
    padding-bottom: 5.2rem;
}

.section-main__wrapper-2 {
    display: flex;
    flex-direction: column;
    > * {
        display: block;
        vertical-align: inherit;
    }
}

.header {
    &__nav-bar {
        padding: 1rem 2rem;
        &:before {
            right: -5.1rem;
            bottom: -3.2rem;
        }
    }
    .btn-menu {
        margin: 0 -7px 0 0;
    }
}

// @name Footer
.footer {
    padding: 10px 0 25px;

    .footer__wrapper-2 {
        margin: 0;
        display: block;
        > * {
            flex: inherit;
            margin: 0 0 2em 0;
        }
    }
}

.header {
    .btn-menu {
        margin: 0 -0.5rem 0 0;
    }

    &__nav-bar {
        padding: 1rem 1.9rem;
    }
}

.section-main__aside {
    padding: 7.6rem 1.5rem 0;
    max-width: 450px;
    margin: 0 auto;
}